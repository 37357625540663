export default {
  TeachersSidebarData: [
    // {
    //   title: "Dashboard",
    //   path: "dashboard",
    // },
    {
      title: "CLASS MANAGEMENT",
      subNav: [
        {
          title: "MY CLASS",
          path: "myClass",
          cName: "sub-nav",
        },
        {
          title: "1:1 UPCOMING SCHEDULE",
          path: "classSchedule",
          cName: "sub-nav",
        },
        {
          title: "CLASS CONDUCTED",
          path: "ClassConducted",
          cName: "sub-nav",
        },
        {
          title: "CLASS PENDING",
          path: "classPending",
          cName: "sub-nav",
        },
        {
          title: "Answer Request",
          path: "answerReq",
          cName: "sub-nav",
        },
      ],
    },

    // {
    //   title: "UPCOMING LIVE SCHEDULE",
    //   path: "upcomingSchedule",
    // },
    // {
    //   title: "FEEDBACK",
    //   path: "feedback",
    // },
    // {
    //   title: "CLASS MANAGEMENT",
    //   subNav: [
    //     {
    //       title: "MY CLASS",
    //       path: "myClass",
    //       cName: "sub-nav",
    //     },
    //     {
    //       title: "MY 1:N BATCHES",
    //       path: "UpdateScholershipPattern",
    //       cName: "sub-nav",
    //     },
    //     {
    //       title: "MY ENROLLMENTS",
    //       path: "UpdateScholershipPattern",
    //       cName: "sub-nav",
    //     },
    //     {
    //       title: "PTM SCHEDULE",
    //       path: "UpdateScholershipPattern",
    //       cName: "sub-nav",
    //     },
    //   ],
    // },
    {
      title: "DEMO MANAGEMENT",
      subNav: [
        {
          title: "MY DEMO",
          path: "myDemo",
          cName: "sub-nav",
        },
        {
          title: "DEMO SCHEDULED",
          path: "demoSchedule",
          cName: "sub-nav",
        },
        {
          title: "CONDUCTED DEMO",
          path: "demoConducted",
          cName: "sub-nav",
        },
        {
          title: "PENDING DEMO",
          path: "demoPending",
          cName: "sub-nav",
        },
        // {
        //   title: "PTM SCHEDULE",
        //   path: "UpdateScholershipPattern",
        //   cName: "sub-nav",
        // },
      ],
    },
    {
      title: "TIME AVAILABILITY",
      path: "timeAvailability",
    },
    {
      title: "PAYMANTS & COUPONS",
      path: "paymantsAndCoupons",
    },
    {
      title: "Notification",
      path: "notification",
    },
    // {
    //   title: "TEACHER FEEDBACK",
    //   path: "teacherFeedback",
    // },
  ],
};
