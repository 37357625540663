export default {
  ConfigurationSidebarData: [
    {
      title: "INSTALLMENT SETTING",
      path: "installmentSetting",
    },
    {
      title: "BBB SERVER TIMINGS",
      path: "bbbServerTimings",
    },
    {
      title: "QUESTION CONFIG",
      path: "questionConfig",
    },

  ]
};