import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import {
  getTableDataSlots,
  today,
  usePagination,
} from "../../Utils/appConstants";
import Pagination from "@mui/material/Pagination";
import SubmitButton from "../../Components/searchButton";
import {
  getAffilateMarketingLead,
  getCountryList,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import moment from "moment";
import AffilateLeadDeatilsPopup from "../../Components/affilateLeadDeatilsPopup";
import { getAffiliateUsers } from "../../Redux/Actions/SuperAdminAction";
import CustomDropdown from "../../Components/customDropdown";
import AffiliateDemoAlignPopup from "../../Components/affiliateDemoAlignPopup";

export default function AffiliateMarketing() {
  const dispatch: Function = useDispatch();
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);
  const [startDate, setStartDate] = useState<any>(
    moment(lastWeek).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(today);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [leads, setLeads] = useState([]);
  const [country, setCountry] = useState<any>({
    _id: 226,
    phonecode: 1,
    name: "UNITED STATES",
  });
  const [countryList, setCountryList] = useState([]);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<any>(3);
  const [affiliateUsersList, setAffiliateUsersList] = useState([]);
  const [affiliateUser, setAffiliateUser] = useState<any>([]);
  const [resetAffiliateUserList, setResetAffiliateUserList] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [showEditSchedule, setShowEditSchedule] = useState(false);
  const [showDemoSchedule, setShowDemoSchedule] = useState(false);

  useEffect(() => {
    getDemoReq();
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.msg) as any);
        }
      )
    );
    const postData: any = {
      departmentId: 18,
      userRoleId: 20,
    };

    dispatch(
      getAffiliateUsers(
        postData,
        (res: any) => {
          setAffiliateUsersList(res?.data);
        },
        () => { }
      )
    );
  }, []);

  const getDemoReq = () => {
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 6,
      countryId: country?._id,
      refId: affiliateUser?.empId,
    };
    dispatch(
      getAffilateMarketingLead(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        (error: any) => { }
      )
    );
  };

  const handleCancelClose = (value: any) => {
    setOpenCancelPopup(false);
  };
  const handleClose = (value: any) => {
    setOpenPopup(false);
  };
  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };

  const onChangeAffiliateUser = (value: any) => {
    setAffiliateUser(value);
  };

  console.log('affiliateUser', affiliateUser)

  const onChangeCountryName = (e: any, index: any, data: any) => {
    setLeads([]);
    setSelectedIndex(index);
    setCountry(data);
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 6,
      countryId: data?._id,
      refId: affiliateUser?.empId,
    };
    dispatch(
      getAffilateMarketingLead(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        (error: any) => { }
      )
    );
  };

  const onPageChange = (page: number, rowsPerPage: number) => {
    let postdata: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 6,
      countryId: country?._id,
      refId: affiliateUser?.empId,
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    };

    dispatch(
      getAffilateMarketingLead(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        () => { }
      )
    );
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }

    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleEdit = (value: any) => {
    setSelectedData(value);
    setOpenPopup(true);
    setShowEditSchedule(true);
  };

  const renderListMode = () => {
    return (
      <>
        <div className="">
          <div className="d-flex justify-content-between mt-4">
            <div className="justify-content-start mt-4 ms-3">
              {countryList?.map((item: any, index: any) => {
                return (
                  <button
                    className={`btn btn-sm me-4 ${selectedIndex === index + 1
                      ? "btn-success"
                      : "btn-secondary"
                      }`}
                    onClick={(e) => onChangeCountryName(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                );
              })}
            </div>
            <div className="d-flex justify-content-end mt-4">
              {leads?.length !== 0 ? (
                <div className="d-flex paginationdiv justify-content-end align-items-center ms-2  ">
                  <Pagination
                    count={totalPages}
                    size="large"
                    page={currentPage}
                    variant="outlined"
                    shape="rounded"
                    color={"primary"}
                    onChange={handleChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={leads ? leads : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}
        <div>
          <PaginationTable
            tableData={leads ? leads : []}
            pagename={"AffilateMarketingDemoSchedule"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "STUDENT NAME",
              "STUDENT MOBILE NUMBER",
              "STUDENT EMAIL",
              "CREATED DATE",
              "DEMO STATUS",
              "ACTION",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
          />
        </div>
      </>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getDemoReq();
  };
  return (
    <div className="contentBox">
      <PageTitle name={"AFFILATED LEADS"} />
      <div className="formDiv">
        <form className="text-center " onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex flex-wrap Justify-content-between ">
            <div className="col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"Start Date:"} />
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={today}
              />
            </div>
            <div className=" col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"End Date:"} />
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={today}
              />
            </div>
            <div className=" col-md-4 d-flex mb-2 align-items-center">
              <LabelField lableName={"Affiliate Users"} />
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Affiliate User"
                  setInputText={(value: any) => onChangeAffiliateUser(value)}
                  value={affiliateUser}
                  options={affiliateUsersList}
                  reset={resetAffiliateUserList}
                />
              </div>
            </div>

            <div className="d-flex col-md-2 ms-3  ">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
        {renderListMode()}
      </div>
      <AffilateLeadDeatilsPopup
        open={openCancelPopup}
        handleClose={handleCancelClose}
        data={selectedData}
      // getList={getList}
      />
      <AffiliateDemoAlignPopup
        open={openPopup}
        handleClose={handleClose}
        showEditSchedule={showEditSchedule}
        showDemoSchedule={showDemoSchedule}
        data={selectedData}
        pageName={"affiliateMarketing"}
        getDemoReq={getDemoReq}
      />
    </div>
  );
}
