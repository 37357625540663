import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import SubmitButton from "../../Components/submitButton";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from "../../Utils/appConstants";
import { useDispatch } from "react-redux";
import {
  getMeetingList,
  getSubjectList,
  deleteBbbMeetingId,
  updateBbbMeeting,
  getBBBTeacherList,
} from "../../Redux/Actions/AdminAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  startsWithSpace,
  hasWhiteSpace,
  length_Five,
  length_OneHundredFifty,
  length_Thirty,
  length_Hundred,
} from "../../Utils/validators";

export default function UpdateBBMeeting() {
  const dispatch: Function = useDispatch();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [meetingList, setmeetingList] = useState([]);
  const [isEditMode, setisEditMode] = useState(false);
  const [mailID, setMailID] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [meeting, setMeeting] = useState<string>("");
  const [alternatePassword, setAlternatePassword] = useState<string>("");
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [inviteLink, setInviteLink] = useState<any>("");
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [resetTeacher, setResetTeacher] = useState(false);

  useEffect(() => {
    updatedata();
    dispatch(
      getBBBTeacherList(
        "",
        (res: any) => {
          setTeacherList(res);
        },
        () => { }
      )
    );
  }, []);

  const updatedata = () => {
    dispatch(
      getSubjectList(
        {},
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeTeacher = (value: any) => {
    setTeacher(value?._id);
    setResetTeacher(false);
    dispatch(
      getMeetingList(
        {
          empId: value._id,
        },
        (res: any) => {
          setmeetingList(res);
        },
        () => { }
      )
    );
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
      };
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setMailID(value.meetingId);
    setPassword(value.password);
    setMeeting(value.meetingId);
    setAlternatePassword(value.attentivePass);
    setInviteLink(value.meetingInviteLink);
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const Postdata = {
      meetingId: meeting,
      mailId: mailID,
      password: password,
      attentivePass: alternatePassword,
      subjectId: subject,
      meetingInviteLink: inviteLink,
    };
    dispatch(
      updateBbbMeeting(
        Postdata,
        meeting,
        (res: any) => {
          setisEditMode(false);
          dispatch(
            getMeetingList(
              {
                subjectId: subject,
              },
              (res: any) => {
                setmeetingList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };
  const handleDelete = (value: any) => {
    dispatch(
      deleteBbbMeetingId(
        value.id,
        () => {
          dispatch(
            getMeetingList(
              {
                subjectId: value.subjectId,
              },
              (res: any) => {
                setmeetingList(res);
                updatedata();
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };

  const onChangeInviteLink = (value: any) => {
    setInviteLink(value);
  };

  /***********************email**************************/
  const onChangeMail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setMailID(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  const renderListMode = () => {
    return (
      <>
        {meetingList?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center pt-3 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={meetingList ? meetingList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={meetingList ? meetingList : []}
          pagename={"meetingList"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Teacher Name",
            "Mail ID",
            "Password",
            "Meeting ID",
            "Alternative Password",
            "Invitaion Link",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const validation = () => {
    return (
      mailID === "" ||
      mailID.length < length_Five - 1 ||
      password === "" ||
      password.length < length_Five - 1 ||
      meeting === "" ||
      meeting.length < length_Five - 1 ||
      alternatePassword === "" ||
      alternatePassword.length < length_Five - 1 ||
      teacher === "" ||
      inviteLink === "" ||
      inviteLink?.length < length_Five - 1
    );
  };

  const renderEditMode = () => {
    return (
      <>
        <div className="d-flex mb-1 align-items-center col-7 px-3 pb-0">
          <div className="col-4 align-item-center d-flex">
            <LabelField lableName={"Mail ID"} />
          </div>
          <div className="col-8  ">
            <InputField
              value={mailID}
              placeholder="Enter Mail ID"
              onChangeInput={(value: any) => onChangeMail(value)}
              maxlength={length_OneHundredFifty}
              isRequired={true}
            />
          </div>
        </div>
        <div className="d-flex mb-1 align-items-center col-7 px-3 pb-0 ">
          <div className="col-4 align-item-center d-flex">
            <LabelField lableName={"Password"} />
          </div>
          <div className="col-8  ">
            <InputField
              placeholder="Enter Password"
              value={password}
              onChangeInput={(value: any) => setPassword(value)}
              maxlength={length_Thirty}
              minlength={length_Five}
              isRequired={true}
            />
          </div>
        </div>
        <div className="d-flex mb-1 align-items-center col-7 px-3 pb-0">
          <div className="col-4 align-item-center d-flex">
            <LabelField lableName={"Meeting ID"} />
          </div>
          <div className="col-8  ">
            <InputField
              placeholder="Enter Meeting ID"
              value={meeting}
              onChangeInput={(value: any) => setMeeting(value)}
              isRequired={true}
            />
          </div>
        </div>
        <div className="d-flex mb-1 align-items-center col-7 px-3 pb-0">
          <div className="col-4 align-item-center d-flex">
            <LabelField lableName={"Attentive Password"} />
          </div>
          <div className="col-8  ">
            <InputField
              placeholder="Enter Attentive Password"
              value={alternatePassword}
              onChangeInput={(value: any) => setAlternatePassword(value)}
              maxlength={length_Thirty}
              minlength={length_Five}
              isRequired={true}
            />
          </div>
        </div>
        <div className="d-flex mb-1 align-items-center col-7 px-3 pb-0">
          <div className="col-4 align-item-center d-flex">
            <LabelField lableName={"Invite Link"} />
          </div>
          <div className="col-8  ">
            <InputField
              placeholder="Enter Link"
              value={inviteLink}
              onChangeInput={(value: any) => onChangeInviteLink(value)}
              maxlength={length_Hundred}
              minlength={length_Five}
              isRequired={true}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 col-7">
          <SubmitButton name={"Save & Update"} validation={validation()} />
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"UPDATE LIVE MEETING ID CREATION"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <form className="formDiv" onSubmit={handleSubmit}>
        <div className="col-7 p-3 pb-0">
          <div className="d-flex mb-2 align-items-center mt-3">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Teacher Name"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Teacher Name"
                setInputText={(value: any) => onChangeTeacher(value)}
                value={teacher}
                options={teacherList}
                reset={resetTeacher}
              />
            </div>
          </div>
        </div>
        {isEditMode ? renderEditMode() : renderListMode()}
      </form>
    </div>
  );
}
