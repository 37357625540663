import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import { useDispatch } from "react-redux";
import "../../Assets/css/calender.css";
import moment from "moment";
import {
  getStudentListByTeacherID,
  getUserAnswerReq,
  submitUserAnswerPoints,
} from "../../Redux/Actions/AdminAction";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import { validEndDate } from "../../Utils/errorMsg";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import SubmitButton from "../../Components/submitButton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CustomDropdown from "../../Components/customDropdown";
import parser from "html-react-parser";

export default function MyClass() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [answerReqList, setAnswerReqList] = useState([]);
  const [openPopup, setOpenPopUp] = useState(false);
  const [requestData, setRequestData] = useState<any>();
  const [answerPoints, setAnswerPoints] = useState<any>("");
  const [pointList, setpointList] = useState<any>([]);
  const [resetPoint, setresetPoint] = useState(false);
  const [studentList, setstudentList] = useState<any>([]);
  const [student, setStudent] = useState<any>("");
  const [resetStudent, setresetStudent] = useState(false);
  const [selectedTab, setSelectedTab] = useState<String>("NoReviewed");
  const [filterAnswerData, setfilterAnswerData] = useState<any>([]);
  
  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));
  console.log('answerPoints', answerPoints);
console.log('pointList', pointList);
  useEffect(() => {
    getData();
  }, []);
  useEffect(()=>{
    if(userId){

      const data = {
        teacherId: userId,
      };
      dispatch(
        getStudentListByTeacherID(
          data,
          (res: any) => {
            console.log('StudentList', res);
            setstudentList(res)
          },
          () => {}
        )
      );
    }
  },[userId])

  useEffect(()=>{

    if(requestData?.points){
      let points=[];
      console.log('requestData?.points', requestData?.points);
      for(let i=1;i<=requestData?.points;i++){
        // if(i===1){
        //   points.push({id:0.5, point:0.5})
        // }
        let no=i-1;
        no=no+0.5
        points.push({_id:no, name:no})
        console.log('points1', points);
        const data={
          _id:i,
          name:i
        }
        points.push(data);
        console.log('points2', points);
      }
      console.log('points12354', points);
      setpointList(points)
    }
  },[requestData?.points])

  useEffect(()=>{
    if(selectedTab && answerReqList){
      let filterData=[];
        if(selectedTab==="Reviewed"){
            filterData=answerReqList?.filter((item:any)=>item?.isReview)
        }
        else{
          filterData=answerReqList?.filter((item:any)=>!item?.isReview)
        }
        console.log('filterData', filterData);
        setfilterAnswerData(filterData)
    }
  },[selectedTab,answerReqList])

  const getData = () => {
    const data:any = {
      teacherId: userId,
      startDate: startDate,
      endDate: endDate,
    };
    if(student){
      data["studentId"] = student._id
    }
    dispatch(
      getUserAnswerReq(
        data,
        (res: any) => {
          setAnswerReqList(res);
        },
        () => {}
      )
    );
  };

  const handleView = (item: any) => {
    setOpenPopUp(true);
    setRequestData(item);
    setAnswerPoints(item?.earnedPoints);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const handleAnswerPoints = (value: any) => { 
    console.log('handleAnswerPoints', value);
    if (value <= requestData?.points) {
      setAnswerPoints(value);
    } else {
      dispatch(showErrorSnackbar("points are max than question points") as any);
    }
  };
console.log('requestData', requestData);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      lectureId: requestData?.lecture?._id,
      studentId: requestData?.studentId,
      descTypeQuesId: requestData?._id,
      earnedPoints: answerPoints?._id,
    };
    console.log('data', data);
    dispatch(
      submitUserAnswerPoints(
        data,
        () => {
          setOpenPopUp(false);
          getData();
        },
        () => {}
      )
    );
  };
  

  return (
    <div className="contentBox">
      <PageTitle name={"ANSWER REQUEST"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <div className="row">
          <form className="d-flex">
            <div className="d-flex mb-2 align-items-center me-5">
              <LabelField lableName={"Start Date :"} />
              <DatePicker
                setInputText={(value: any) => {
                  if (value > endDate && endDate) {
                    dispatch(showErrorSnackbar(validEndDate) as any);
                    return;
                  }
                  setStartDate(value);
                }}
                value={startDate}
              />
            </div>
            <div className="d-flex mb-2 align-items-center me-5">
              <LabelField lableName={"End Date :"} />
              <DatePicker
                setInputText={(value: any) => {
                  setEndDate(value);
                }}
                value={endDate}
              />
            </div>
            <div className="d-flex mb-2 align-items-center me-5 mt-1">
              <LabelField lableName={"Student :"} />
              <CustomDropdown
                lableName="Select Student"
                setInputText={(value: any) => setStudent(value)}
                value={student}
                options={studentList}
                reset={resetStudent}
              />

            </div>
            <div>
              <button type="button" className="btn btn-dark mb-2" onClick={getData}>
                Search
              </button>
            </div>
          </form>
          <div className="col-md-12">
            <div className="d-flex align-items-center mb-2">
            <button className={`btn me-2 ${selectedTab==="NoReviewed"?'btn-primary':"btn-secondary"}`}  onClick={()=>setSelectedTab('NoReviewed')}>Not Review</button>
              <button className={`btn me-2 ${selectedTab==="Reviewed"?'btn-primary':"btn-secondary"}`} onClick={()=>setSelectedTab('Reviewed')}>Reviewed</button>
              
            </div>
            <div className="answerTable">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>Sn</th>
                    <th>Student Name</th>
                    <th>Lecture Name</th>
                    <th>Question</th>
                    <th>User Answer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {filterAnswerData?.map((item: any,index:number) => {
                  let code:any={}
                  if(item?.questionType==="6"){
                    code=JSON?.parse(item?.userAnswer);
                  }
                  return (<tr>
                    <td>{index+1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.lecture?.name}</td>
                    <td className="font16">{item?.questionText[0]?.descType==="text" && item?.questionText[0].description?parser(item?.questionText[0].description):null}</td>
                    <td>{item?.questionType==="6"?<div>
                        {code?.html?<div><b>HTML:</b> {code?.html}</div>:null}
                        {code?.css?<div><b>CSS:</b> {code?.css}</div>:null}
                        {code?.js?<div><b>JS:</b> {code?.js}</div>:null}
                        {code?.python?<div><b>PYTHON:</b> {code?.python}</div>:null}
                    </div> :item?.userAnswer}</td>
                    <td> <button
                        type="button"
                        className="btn btn-dark mb-2"
                        onClick={() => handleView(item)}
                      >
                        VIEW
                      </button></td>
                  </tr>)
                })}
                </tbody>
              </table>
            </div>
            {/* {filterAnswerData?.map((item: any) => {
              return (
                <div className="studentCard mb-2">
                  <div className="d-flex">
                    <div className="col-md-3 d-flex">
                      <div className="userImgDiv d-flex align-items-center me-2">
                        <img src={user} alt="User" className="userImg" />
                      </div>
                      <div className="me-2 w-100">
                        <h6 className="fw-bold">{item?.name}</h6>
                        <h6>
                          Reviewed:{" "}
                          {item?.isReview ? (
                            <span className="text-success">Yes</span>
                          ) : (
                            <span className="text-danger">No</span>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="col-md-8 scheduleBox rounded">
                      <div className="ms-1 text-primary">
                        <h6>{item?.userAnswer}</h6>
                      </div>
                    </div>
                    <div className="col-md-1 text-center align-content-center">
                      <button
                        type="button"
                        className="btn btn-dark mb-2"
                        onClick={() => handleView(item)}
                      >
                        VIEW
                      </button>
                    </div>
                  </div>
                  <div className="gradeDiv mt-2"></div>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="ansRequestModel"
        open={openPopup}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPopup}>
          <div className="ansRequestModelBox p-4 ">
            <div className="d-flex justify-content-end">
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={handleClose}
                />{" "}
              </div>
            </div>
            <div className="AnswerRequestCard mt-2">
              <div className="row mx-0">
              <div className="col-md-4 d-flex align-items-center">
                  <div className="d-flex">
                    <div className="fw-bold me-2">Student Name:-</div>
                    <h6>{requestData?.name}</h6>
                  </div>
                </div>
                <div className="col-md-4 d-flex align-items-center">
                  <div className="d-flex">
                    <div className="fw-bold me-2">Lecture Name:-</div>
                    <h6>{requestData?.lecture?.name}</h6>
                  </div>
                </div>
                <div className="col-md-4 d-flex align-items-center">
                  <div className="d-flex">
                    <div className="fw-bold me-2">Topic Name:-</div>
                    <h6>{requestData?.topic?.name}</h6>
                  </div>
                </div>
                {requestData?.exam?<div className="col-md-4 d-flex align-items-center">
                  <div className="d-flex">
                    <div className="fw-bold me-2">Exam Name:-</div>
                    <h6>{requestData?.exam?.name}</h6>
                  </div>
                </div>:null}  
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <div className="fw-bold me-2">Max Points:-</div>
                    <h6>{requestData?.points}</h6>
                  </div>
                </div>
                {requestData?.isReview?<div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <div className="fw-bold me-2">User Answer Points:-</div>
                    <h6><input
                        type="text"
                        placeholder="Enter Answer Points"
                        className="form-control "
                        value={answerPoints}
                        onChange={(e) => handleAnswerPoints(e.target.value)}
                        disabled={requestData?.isReview}
                      /></h6>
                  </div>
                </div>:null}
              </div>
              <div className="row mx-0">
                <div className="mb-2 d-flex">
                  <label className="fw-bold">Question:</label>
                  <p className="mb-0 ms-2 font16">{requestData?.questionText[0].description?parser(requestData?.questionText[0].description):null}</p>
                </div>
                
              </div>
              <div className="UserAnswerBox mt-3">
                  <div className="row mx-0">
                    <div className="col-md-6">
                      <div className="userAnsBox">
                      <h5 className="fw-bold mb-2">User Answer:</h5>
                      {/* <p>{requestData?.userAnswer}</p> */}
                      <p>
                      {requestData?.questionType==="6"?<div>
                        {JSON?.parse(requestData?.userAnswer)?.html?<div><b>HTML:</b> {JSON?.parse(requestData?.userAnswer)?.html}</div>:null}
                        {JSON?.parse(requestData?.userAnswer)?.css?<div><b>CSS:</b> {JSON?.parse(requestData?.userAnswer)?.css}</div>:null}
                        {JSON?.parse(requestData?.userAnswer)?.js?<div><b>JS:</b> {JSON?.parse(requestData?.userAnswer)?.js}</div>:null}
                        {JSON?.parse(requestData?.userAnswer)?.python?<div><b>PYTHON:</b> {JSON?.parse(requestData?.userAnswer)?.python}</div>:null}
                    </div> :requestData?.userAnswer}
                      </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                    <div  className="userAnsBox">
                      <h5 className="fw-bold mb-2">Lurnigo Answer:</h5>
                      <p>{requestData?.answerText?parser(requestData?.answerText):null}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row mt-4 justify-content-center">
                  <div className="col-md-12 px-2 mx-0 mt-0 row d-flex Justify-content-between">
                    <div className="mt-0 row mx-0 d-flex align-items-center">
                      {requestData?.isReview? null: <div className="col-md-4">
                        <label className="fw-bold mb-3">Answer Points:</label>
                        
                       
                       
                      <CustomDropdown
                          lableName="Select Points"
                          setInputText={(value: any) => setAnswerPoints(value)}
                          value={answerPoints}
                          options={pointList}
                          reset={resetPoint}
                        />
                        
                      </div>}
                      {requestData?.isReview?null:<div className="text-center col-md-4">
                        <SubmitButton name={"Submit"} />
                      </div>}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
