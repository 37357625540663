import uploadImage from "../Assets/images/upload.png";
import uploadPDF from "../Assets/images/file-upload-blank.png";
import uploadedPDF from "../Assets/images/file-upload.png";
import uploadVideo from "../Assets/images/upload-video.png";
import Edit from "../Assets/images/edit-grey.png";

interface props {
  lableName?: any;
  value: any;
  handleChange: any;
  doctype?: any;
  removelabel?: any;
  isRequired?: any;
}

export default function ImagePickerAndViewer({
  value,
  handleChange,
  doctype,
  isRequired,
}: props) {
  const renderPDF = () => {
    if (value) {
      return (
        <img
          src={uploadedPDF}
          style={{
            width: "50px",
            height: "50px",
          }}
          alt={""}
        />
      );
    } else {
      return (
        <img
          src={uploadPDF}
          style={{
            width: "50px",
            height: "50px",
          }}
          alt={""}
        />
      );
    }
  };

  const renderVideo = () => {
    if (value && value !== " ") {
      return (
        <>
          <video
            className="videoClass"
            controls
            style={{
              maxWidth: "210px",
              maxHeight: "175px",
            }}
          >
            <source src={value} type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </>
      );
    } else {
      return (
        <img
          src={uploadVideo}
          style={{
            width: "50px",
            height: "50px",
          }}
          alt={""}
        />
      );
    }
  };

  const renderImage = () => {
    if (value && value !== " ") {
      return (
        <img
          src={value}
          style={{
            height: "50px",
            maxWidth: "100%",
          }}
          alt={""}
        />
      );
    } else {
      return (
        <img
          src={uploadImage}
          style={{
            height: "50px",
            maxWidth: "100%",
          }}
          alt={""}
        />
      );
    }
  };

  const renderMediaInput = () => {
    if (doctype === "pdf") {
      return renderPDF();
    } else if (doctype === "video") {
      return renderVideo();
    } else if (!doctype) {
      return renderImage();
    }
    <img
      src={Edit}
      className="cursor"
      alt="Edit"
      style={{
        width: "13px",
        position: "absolute",
        right: "0",
        bottom: "0px",
      }}
    />;
  };

  return (
    <div className="d-flex mb-2 align-items-center">
      <label className="custom-file-upload fas cursor ">
        {renderMediaInput()}
        <input
          style={{ display: "none" }}
          type="file"
          onChange={(e) => handleChange(e)}
          accept={
            doctype === "pdf"
              ? ".pdf"
              : doctype === "video"
              ? "video/mp4"
              : "image/png, image/gif, image/jpeg"
          }
        />
      </label>
      {isRequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}
