import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Interface } from "readline";

interface props {
  lableName:string,
}

export default function LabelField({  lableName}: props) {
 
  return (
     
    <label className='label me-2'>{ lableName}</label>
 
  );
}
