import React from "react";
import { Route, Routes } from "react-router-dom";
// import CounsellingLeads from '../SalesPanel/counsellingLeads';
import Header from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import adminSidebarData from "./adminSidebarData";
// import LandingPageLeads from './landingPageLeads';
import CreateParentCourse from "./createParentCourse";
import UpdateParentCourse from "./updateParentCourse";
import CreateExam from "./createExam";
import UpdateExam from "./updateExam";
import ErrorSnackbar from "../../Components/errorSnackbar";
import SuccessSnackbar from "../../Components/successSnackbar";
import CreateLanguage from "./createLanguage";
import UpdateLanguage from "./updateLanguage";
import CreateAssignExam from "./createAssignExam";
import CreateSubCategory from "./createSubCategory";
import UpdateSubCategory from "./updateSubCategory";
import CreateSection from "./createSection";
import UpdateSection from "./updateSection";
import CreateMainCategory from "./createMainCategory";
import UpdateMainCategory from "./updateMainCategory";
import CreatePackage from "../DataEntry/createPackage";
import CreateBBMeeting from "./createBBMeeting";
import UpdateBBMeeting from "./updateBBMeeting";
import Dashboard from "../TeachersPanel/dashboard";
import UpcomingSchedule from "../TeachersPanel/upcomingSchedule";
import CreateTeacher from "../SuperAdmin/createTeacher";
import UpdateTeacher from "../SuperAdmin/updateteacher";
import CreateExamDescription from "./createExamDescription";
import UpdateExamDescription from "./updateExamDescription";
import CreateCourseDescription from "./createCourseDescription";
import UpdateCourseDescription from "./updateCourseDescription";
import CreateSubject from "./createSubject";
import UpdateSubject from "./updateSubject";
import CreatePattern from "./createPattern";
import UpdatePattern from "./updatePattern";
import WIP from "../wip";
import CreateScholershipPattern from "./createScholershipPattern";
import UpdateScholershipPattern from "./updateScholershipPattern";
import AssignSubject from "./assignSubject";
import CreateFeature from "./createFeature";
import AssignFeature from "./AssignFeature";
import CreateCouponCode from "./createCouponCode";
import UpdateCouponCode from "./updateCouponCode";
import CreateGlobalSetting from "./createGlobalSetting";
import CreateUser from "./createUser";
import UpdateFeature from "./updateFeature";
import UpdateGlobalSetting from "./updateGlobalSetting";

export default function AdminPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={adminSidebarData?.AdminSidebarData} />
      <div className="dashboard">
        <Routes>
          <Route path="/" element={<CreateParentCourse />} />
          <Route path="createParentCourse" element={<CreateParentCourse />} />
          <Route path="updateParentCourse" element={<UpdateParentCourse />} />
          <Route path="createExam" element={<CreateExam />} />
          <Route path="updateExam" element={<UpdateExam />} />
          <Route path="createAssignExam" element={<CreateAssignExam />} />
          <Route path="createSubject" element={<CreateSubject />} />
          <Route path="updateSubject" element={<UpdateSubject />} />
          <Route path="assignSubject" element={<AssignSubject />} />
          <Route path="createLanguage" element={<CreateLanguage />} />
          <Route path="updateLanguage" element={<UpdateLanguage />} />
          <Route path="createSubCategory" element={<CreateSubCategory />} />
          <Route path="updateSubCategory" element={<UpdateSubCategory />} />
          <Route path="createSection" element={<CreateSection />} />
          <Route path="updateSection" element={<UpdateSection />} />
          <Route path="createMainCategory" element={<CreateMainCategory />} />
          <Route path="updateMainCategory" element={<UpdateMainCategory />} />
          <Route path="createPackage" element={<CreatePackage />} />
          <Route path="createBBMeeting" element={<CreateBBMeeting />} />
          <Route path="updateBBMeeting" element={<UpdateBBMeeting />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="upcomingSchedule" element={<UpcomingSchedule />} />
          <Route path="createTeacher" element={<CreateTeacher />} />
          <Route path="updateTeacher" element={<UpdateTeacher />} />
          <Route
            path="createExamDescription"
            element={<CreateExamDescription />}
          />
          <Route
            path="updateExamDescription"
            element={<UpdateExamDescription />}
          />
          <Route
            path="createCourseDescription"
            element={<CreateCourseDescription />}
          />
          <Route
            path="updateCourseDescription"
            element={<UpdateCourseDescription />}
          />
          <Route path="createPattern" element={<CreatePattern />} />
          <Route path="updatePattern" element={<UpdatePattern />} />
          <Route
            path="createScholershipPattern"
            element={<CreateScholershipPattern />}
          />
          <Route
            path="UpdateScholershipPattern"
            element={<UpdateScholershipPattern />}
          />
          <Route path="createUser" element={<CreateUser />} />
          <Route path="createFeature" element={<CreateFeature />} />
          <Route path="assignFeature" element={<AssignFeature />} />
          <Route path="createCouponCode" element={<CreateCouponCode />} />
          <Route path="updateCouponCode" element={<UpdateCouponCode />} />
          <Route path="createGlobalSetting" element={<CreateGlobalSetting />} />
          <Route path="updateGlobalSetting" element={<UpdateGlobalSetting />} />
          <Route path="updateFeature" element={<UpdateFeature />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
