export default {
  AdminSidebarData: [
    {
      title: "COURSE TYPE",
      subNav: [
        {
          title: "CREATE",
          path: "createParentCourse",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateParentCourse",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "EXAM NAME",
      subNav: [
        {
          title: "CREATE",
          path: "createExam",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateExam",
          cName: "sub-nav",
        },
      ],
    },

    // {
    //   title: "ASSIGN EXAM NAME",
    //   path: "createAssignExam",
    // },
    {
      title: "SUBJECT",
      subNav: [
        {
          title: "CREATE",
          path: "createSubject",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "UpdateSubject",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "ASSIGN SUBJECT",
      path: "assignSubject",
    },
    {
      title: "COUPON CODE",
      subNav: [
        {
          title: "CREATE",
          path: "createCouponCode",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "UpdateCouponCode ",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "GLOBAL SETTING FOR EXTENSION",
      subNav: [
        {
          title: "CREATE",
          path: "createGlobalSetting",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "UpdateGlobalSetting",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "TEACHER",
      subNav: [
        {
          title: "CREATE",
          path: "createTeacher",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "UpdateTeacher ",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "SUB CATEGORY",
      subNav: [
        {
          title: "CREATE",
          path: "createSubCategory",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateSubCategory",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: "SECTION",
      subNav: [
        {
          title: "CREATE",
          path: "createSection ",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateSection",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "MAIN CATEGORY",
      subNav: [
        {
          title: "CREATE",
          path: "createMainCategory",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "UpdateMainCategory",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: "BBB MEETING ID",
      subNav: [
        {
          title: "CREATE",
          path: "createBBMeeting",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateBBMeeting",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "EXAM DESCRIPTION",
      subNav: [
        {
          title: "CREATE",
          path: "createExamDescription",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateExamDescription",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "COURSE DESCRIPTION",
      subNav: [
        {
          title: "CREATE",
          path: "createCourseDescription",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateCourseDescription",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "FEATURE",
      subNav: [
        {
          title: "CREATE",
          path: "createFeature",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateFeature",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: "ASSIGN FEATURE",
      path: "assignFeature",
    },
    {
      title: "LANGUAGE",
      subNav: [
        {
          title: "CREATE",
          path: "createLanguage",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateLanguage",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "PATTERN",
      subNav: [
        {
          title: "CREATE",
          path: "createPattern",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updatePattern",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "SCHOLERSHIP PATTERN",
      subNav: [
        {
          title: "CREATE",
          path: "createScholershipPattern",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "UpdateScholershipPattern",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "USER CREATION",
      path: "createUser",
    },


  ]
};
