import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import CouncellorSidebarData from "./councellorPanleSidebarData";
import Leads from "./leads";
import WIP from "../wip";
import CounsellorPayments from "./counsellorPayments";
export default function CouncellorPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={CouncellorSidebarData?.CouncellorSidebarData} />
      <div className="dashboard">
        <Routes>
          <Route path="/" element={<Leads />} />
          <Route path="leads" element={<Leads />} />
          <Route path="counsellorPayments" element={<CounsellorPayments />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
