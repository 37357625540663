import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { QuestionType } from "../Utils/dataConstant";
import ImagePickerAndViewer from "./imagePickerAndViewer";
import {
  validateIsFileImageType,
  validateIsFileVideoType,
} from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFileVideoTypeErrMsg,
} from "../Utils/errorMsg";
import { uploadAcademicsImg } from "../Redux/Actions/AdminAction";
import Addmorebtn from "./addmorebtn";
import DeleteIcon from "@mui/icons-material/Delete";
import AcademicsOption from "./academicsOption";
import AcademicsHint from "./academicsHint";
import AcademicsAnswer from "./academicsAnswer";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import TextCKEditor from "./textCKEditor";
import AcademicsMsqOption from "./academicsMsqOption";

export default function AcademicsPracticeQuestion(props: any) {
  const dispatch = useDispatch();
  const config = {
    loader: { load: ["input/asciimath"] },
    asciimath: {
      displaystyle: true,
      delimiters: [
        ["$", "$"],
        ["`", "`"],
      ],
    },
  };
  const [classContentArray, setClassContentArray] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [menuArray, setmenuArray] = useState<any>();
  const [showMCQ, setShowMCQ] = useState(false);
  const [optionArr, setOptionArr] = useState<any[]>([{}]);
  const [mcqHintList, setMcqHintList] = useState<any>([]);
  const [mcqAnsList, setMcqAnsList] = useState<any>([]);
  const [showFixedQue, setShowFixedQue] = useState(false);
  const [showDescriptiveQue, setShowDescriptiveQue] = useState(false);
  const [questionType, setQuestionType] = useState<any>();
  const [showMSQ, setShowMSQ] = useState(false);
  const [dndPartAData, setdndPartAData] = useState<any>([]);
  const [dndPartBData, setdndPartBData] = useState<any>([]);
  const initialVal: {
    descType: any;
    description: any;
    isCorrect: any;
    answerType: number;
  }[] = [
      {
        descType: "text",
        description: "",
        isCorrect: "true",
        answerType: 1,
      },
    ];
  const [fixedAnswer, setFixedAnswer] = useState<any>(initialVal);
  useEffect(() => {
    props?.getData?.map((value: any) => {
      setClassContentArray(value?.question);
      setFixedAnswer(value?.option);
      setOptionArr(value?.option);
      setQuestionType(parseInt(value?.questionType));
      if (parseInt(value?.questionType) === 5) {
        console.log('value?.option', value?.option);
        const filterPartA=value?.option?.filter((data:any)=>data?.part==="partA")
        const filterPartB=value?.option?.filter((data:any)=>data?.part==="partB")
        setdndPartAData(filterPartA)
        setdndPartBData(filterPartB)
        
      }
      if (parseInt(value?.questionType) === 1) {
        setShowMCQ(true);
      } else {
        setShowMCQ(false);
      }
      if (parseInt(value?.questionType) === 2) {
        setShowFixedQue(true);
      } else {
        setShowFixedQue(false);
      }
      if (parseInt(value?.questionType) === 3) {
        setShowMSQ(true);
      } else {
        setShowMSQ(false);
      }
      if (parseInt(value?.questionType) === 4) {
        setShowDescriptiveQue(true);
      } else {
        setShowDescriptiveQue(false);
      }
    });

    setmenuArray(QuestionType);
  }, []);
  const showInputMenu = (inputType: any) => {
    const newArrr = [...classContentArray];
    const index = newArrr?.length || 0;
    const newItem = {
      descType: inputType,
      description: "",
      contentOrder: index + 1,
    };
    newArrr.push(newItem);
    setClassContentArray(newArrr);
  };
/**********************DND Question***************************** */
  const showDNDInputMenu = (inputType: any,part:string) => {
    const newArrrA = [...dndPartAData];
    const newArrrB = [...dndPartBData];
    let index = 0;
    if(part==="partA"){
      index=newArrrA?.length||0
    }else{
      index=newArrrB?.length||0
    }
    const newItem = {
      descType: inputType,
      description: "",
      correctMatch:"",
      id: index + 1,
      part:part
    };
    if(part==="partA"){
      newArrrA.push(newItem);
    setdndPartAData(newArrrA);
    }else{
      newArrrB.push(newItem);
      setdndPartBData(newArrrB);
        }    
  };

  const handleCloseDNDParts = (index: any,part:string) => {
    const newArrrA = [...dndPartAData];
    const newArrrB = [...dndPartBData];

    if(part==="partA"){
      if (index > -1) {
        newArrrA.splice(index, 1);
      }
      setdndPartAData(newArrrA);
    }
    else{
      if (index > -1) {
        newArrrB.splice(index, 1);
      }
      setdndPartBData(newArrrB);
    }
    
  };

  const onChangeDndPartData = (index: any, value: any, key: string,part:string) => {
    const newArrrA = [...dndPartAData];
    const newArrrB = [...dndPartBData];

    if(part==="partA"){
      newArrrA[index][key] = value;
    setdndPartAData(newArrrA);
    }else{
      newArrrB[index][key] = value;
      setdndPartBData(newArrrB);
        } 
    // if (key) {
    //   newArrr[index][key] = value;
    //   setClassContentArray(newArrr);
    // }
  };
console.log('dndPartAData', dndPartAData);
console.log('dndPartBData', dndPartBData);
  const onChangeDnDPartImg = (
    e: { preventDefault: () => void; target: { files: any[] } },
    index: any,
    part: string
  ) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadAcademicsImg(
          file,
          (res: any) => {
            onChangeDndPartData(index, res.filePath, "description",part);
          },
          () => { }
        )
      );
    }
  };

  const onPressAdd = () => {
    if (optionArr.length < 4) setOptionArr([...optionArr, {}]);
  };

  function clickText() {
    setShow(!show);
  }
  const handleCloseClassContent = (index: any) => {
    const newArrr = [...classContentArray];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setClassContentArray(newArrr);
  };

  const onChangeClassContentText = (index: any, value: any, key: string) => {
    const newArrr = [...classContentArray];
    if (key) {
      newArrr[index][key] = value;
      setClassContentArray(newArrr);
    }
  };

  const onChangeClassContentImg = (
    e: { preventDefault: () => void; target: { files: any[] } },
    index: any
  ) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadAcademicsImg(
          file,
          (res: any) => {
            onChangeClassContentText(index, res.filePath, "description");
          },
          () => { }
        )
      );
    }
  };

  const handleClose = (index: any) => {
    const newArrr = [...optionArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setOptionArr(newArrr);
  };
  const handleOption = (value: {
    descType: string;
    description: string;
    isCorrect: boolean;
    index: number;
  }) => {
    const item = {
      descType: value.descType,
      description: value.description,
      isCorrect: value.isCorrect,
    };
    optionArr[value.index] = item;
  };

  const handleCorrectOption = (value: {
    index: number;
    isCorrect: boolean;
  }) => {
    let newArray = JSON.parse(JSON.stringify(optionArr));
    newArray?.forEach((item: any) => {
      item.isCorrect = false;
    });
    newArray[value.index].isCorrect = value.isCorrect;
    setOptionArr(newArray);
  };

  const handleCorrectMsqOption = (value: any) => {
    let newArray = JSON.parse(JSON.stringify(optionArr));
    if (value?.isCorrect) {
      newArray[value?.index].isCorrect = value?.isCorrect
      setOptionArr(newArray);
    }
    else {
      newArray[value?.index].isCorrect = value?.isCorrect
      setOptionArr(newArray);
    }
  };

  const storeMcqHint = (val: any) => {
    setMcqHintList(val);
  };
  const storeMcqAnswer = (val: any) => {
    setMcqAnsList(val);
  };

  useEffect(() => {
    setOptionsValue();
  }, [classContentArray, optionArr, mcqHintList, mcqAnsList,dndPartAData,dndPartBData]);

  const setOptionsValue = () => {
    let tempClassContentArray = classContentArray?.map((item: any) => {
      if (item?.descType === "text") {
        return {
          descType: item?.descType,
          description: item.description.replace(/'/g, "&apos;"),
          contentOrder: item?.contentOrder,
        };
      }
      return item;
    });
    let tempOptionArr = optionArr?.map((item: any) => {
      if (item?.descType === "text") {
        return {
          descType: item?.descType,
          description: item.description.replace(/'/g, "&apos;"),
          isCorrect: item?.isCorrect,
          index: item?.index,
        };
      }
      return item;
    });
    const PostData = {
      hint: mcqHintList?.hint,
      answer: mcqAnsList?.answer,
      question: tempClassContentArray,
      options:
        showFixedQue === true
          ? fixedAnswer
          : showMCQ === true
            ? tempOptionArr
            : showMSQ === true
              ? tempOptionArr
              : showDescriptiveQue === true
                ? []
                :questionType===5?
                dndPartAData?.concat(dndPartBData)
                : [],
      questionType: questionType,
    };
    props?.setMcqData(PostData);

    console?.log("PostData123", PostData);
    return PostData;
  };

  const renderOption = () => {
    return optionArr.map((item, index) => {
      return (
        <>
          <div className="position-relative d-flex justify-content-center p-2">
            <div className="w-100">
              <AcademicsOption
                index={index}
                option={item}
                setOptionData={(value: {
                  descType: string;
                  description: string;
                  isCorrect: boolean;
                  index: number;
                }) => handleOption(value)}
                setCorrectOption={(value: {
                  index: number;
                  isCorrect: boolean;
                }) => handleCorrectOption(value)}
                getData={props?.getData}
              />
            </div>
            {optionArr.length === 1 ? null : (
              <span className="text-dark d-flex">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleClose(index)}
                />
              </span>
            )}
          </div>
        </>
      );
    });
  };

  const renderMsqOption = () => {
    return optionArr.map((item, index) => {
      return (
        <>
          <div className="position-relative d-flex justify-content-center p-2">
            <div className="w-100">
              <AcademicsMsqOption
                index={index}
                option={item}
                setOptionData={(value: {
                  descType: string;
                  description: string;
                  isCorrect: boolean;
                  index: number;
                }) => handleOption(value)}
                setCorrectOption={(value: {
                  index: number;
                  isCorrect: boolean;
                }) => handleCorrectMsqOption(value)}
                getData={props?.getData}
              />
            </div>
            {optionArr.length === 1 ? null : (
              <span className="text-dark d-flex">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleClose(index)}
                />
              </span>
            )}
          </div>
        </>
      );
    });
  };

  const onChangeClassContentVideo = (e: any, index: any) => {
    e.preventDefault();
    if (!validateIsFileVideoType(e)) {
      dispatch(showErrorSnackbar(validateIsFileVideoTypeErrMsg) as any);
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => { };
    reader.readAsDataURL(file);

    dispatch(
      uploadAcademicsImg(
        file,
        (res: any) => {
          if (res) {
            dispatch(showSuccessSnackbar("File Succesfully Uploaded") as any);
            onChangeClassContentText(index, res.filePath, "description");
          } else {
            dispatch(showErrorSnackbar("File Not Uploaded") as any);
          }
        },
        () => { }
      )
    );
  };

  const onClickData = (val: any) => {
    setQuestionType(val);
    if (val === 1) {
      setShowMCQ(true);
    } else {
      setShowMCQ(false);
    }
    if (val === 2) {
      setShowFixedQue(true);
    } else {
      setShowFixedQue(false);
    }
    if (val === 3) {
      setShowMSQ(true);
    } else {
      setShowMSQ(false);
    }
    if (val === 4) {
      setShowDescriptiveQue(true);
    } else {
      setShowDescriptiveQue(false);
    }
    if(val===6){
      props?.setIsCoding(true)
    }
    else{
      props?.setIsCoding(false)
    }
  };

  const onChangeFixedAnswer = (index: any, value: any, key: string) => {
    const newArrr = [...fixedAnswer];
    if (key) {
      newArrr[index][key] = value;
      setFixedAnswer(newArrr);
    }
  };
  const renderClassContent = () => {
    return classContentArray?.map((item: any, index: any) => {
      const { descType, description } = item;
      return (
        <>
          <div
            className="position-relative d-flex justify-content-between m-2 p-2 border"
            key={index}
          >
            {descType === "text" ? (
              <div className="m-2 w-100">
                <TextCKEditor
                  value={description}
                  onChange={(value: any) =>
                    onChangeClassContentText(index, value, "description")
                  }
                // height="vh100"
                // width="vw100"
                />
              </div>
            ) : null}
            {descType === "image" ? (
              <div className="m-2">
                <ImagePickerAndViewer
                  lableName={"Image"}
                  value={description}
                  handleChange={(e: any) => onChangeClassContentImg(e, index)}
                  removelabel={true}
                />
              </div>
            ) : null}
            {descType === "video" ? (
              <div className="m-2">
                <ImagePickerAndViewer
                  lableName={"Video"}
                  value={description}
                  handleChange={(value: any) =>
                    onChangeClassContentVideo(value, index)
                  }
                  doctype={"video"}
                />
              </div>
            ) : null}
            <div>
              <span className="text-dark">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleCloseClassContent(index)}
                />
              </span>
            </div>
          </div>
        </>
      );
    });
  };
  const renderDnDPart = (dndData:any,parts:string) => {
    return dndData?.map((item: any, index: any) => {
      const { descType, description,id,correctMatch } = item;
      let startingCharCode = 97;
      return (        
          <div
            className="position-relative d-flex justify-content-between m-2 p-2 border"
            key={index}
          >
            <div className="m-2">{parts==="partA"?String.fromCharCode(startingCharCode + index)+".":index+1+"."}</div>
            {descType === "text" ? (
              <div className="m-2 w-100">
                <TextCKEditor
                  value={description}
                  onChange={(value: any) =>
                    onChangeDndPartData(index, value, "description",parts)
                  }
                // height="vh100"
                // width="vw100"
                />
              </div>
            ) : null}
            {descType === "image" ? (
              <div className="m-2">
                <ImagePickerAndViewer
                  lableName={"Image"}
                  value={description}
                  handleChange={(e: any) => onChangeDnDPartImg(e, index,parts)}
                  removelabel={true}
                />
              </div>
            ) : null}
            <div className="d-flex mt-2">
              {parts==="partA"?<div className="me-2">
               <input type="text" value={correctMatch} onChange={(value: any) =>
                    onChangeDndPartData(index, value?.target?.value, "correctMatch",parts)
                  } style={{width:"50px"}}/>
              </div>:null}
              <span className="text-dark">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleCloseDNDParts(index,parts)}
                />
              </span>
            </div>
          </div>
       
      );
    });
  };
  return (
    <div>
      <div className="w-100 pt-2">
        <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
          {/* <h5 className="fw-bold text-light">Practice Question</h5> */}
          <div className="d-flex">
          <h5 className="fw-bold text-light">Practice Question</h5>
          {questionType === 6?<div className="d-flex align-items-center">
              <span
                className="position-relative addInputbtn"
                // onClick={clickText}
              >
                <div className="cursor ms-2 p-1">
                  {props?.codingType? props?.codingType?.name 
                          : "+ Select Coding Type"}
                </div>
                {!props.isEditMode ? (
                  <div className="position-absolute notiDivPractice">
                    <div
                      className="position-absolute transperentbg"
                      // onClick={() => setShow(false)}
                    ></div>
                    <div
                      className="position-absolute border p-1 font12"
                      style={{
                        background: "#fff",
                        top: "2px",
                        right: "29px",
                        width: "160px",
                        boxShadow: "black -2px 2px 10px -2px",
                      }}
                    >
                      <div className="px-0">
                        {props?.CodingTypeData?.map((c: any) => (
                          <div
                            className="p-2 addQuestionType"
                            style={{ cursor: "pointer" }}
                            onClick={() => props?.setCodingType(c)}
                          >
                            <span className="ms-3 colorgray ">{c?.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </span>
            </div>:null}
          </div>
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <span
                className="position-relative addInputbtn"
                onClick={clickText}
              >
                <div className="cursor ms-2 p-1">
                  {questionType === 1
                    ? "MCQ"
                    : questionType === 2
                      ? "Fixed Question"
                      : questionType === 3
                        ? "MSQ"
                        : questionType === 4
                          ? "Descriptive"
                          : questionType === 5
                          ? "Match The Pair"
                          : questionType === 6
                          ? "Coding"
                          : "+ Add Question Type"}
                </div>
                {!props.isEditMode ? (
                  <div className="position-absolute notiDivPractice">
                    <div
                      className="position-absolute transperentbg"
                      onClick={() => setShow(false)}
                    ></div>
                    <div
                      className="position-absolute border p-1 font12"
                      style={{
                        background: "#fff",
                        top: "2px",
                        right: "29px",
                        width: "160px",
                        boxShadow: "black -2px 2px 10px -2px",
                      }}
                    >
                      <div className="px-0">
                        {menuArray?.map((c: any) => (
                          <div
                            className="p-2 addQuestionType"
                            style={{ cursor: "pointer" }}
                            onClick={() => onClickData(c?._id)}
                          >
                            <span className="ms-3 colorgray ">{c?.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </span>
            </div>
          </div>
        </div>
        {showMCQ === true ? (
          <div className="mt-2 border border-black">
            <div className="m-2 border border-black">
              <div className="p-3 bg-dark d-flex align-items-center justify-content-between mt-2 mx-2">
                <h5 className="fw-bold text-light">Question Data</h5>
                <div className=" d-flex">
                  <div
                    className="cursor addInputbtn ms-2 p-1"
                    onClick={(id: any) => showInputMenu("text")}
                  >
                    + Text
                  </div>
                  <div
                    className="cursor addInputbtn ms-2 p-1"
                    onClick={(id: any) => showInputMenu("image")}
                  >
                    + Image
                  </div>
                  <div
                    className="cursor addInputbtn ms-2 p-1"
                    onClick={(id: any) => showInputMenu("video")}
                  >
                    + Video
                  </div>
                </div>
              </div>
              {renderClassContent()}

              <div className="mx-2 mb-2 border">
                <div>{renderOption()}</div>
                {optionArr.length < 4 ? (
                  <div className="d-flex justify-content-end m-2">
                    <Addmorebtn
                      name="Add New Option"
                      handleClick={onPressAdd}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="m-2 border border-black">
              <AcademicsHint
                setMcqHint={(value: any) => storeMcqHint(value)}
                getData={props?.getData}
              />
            </div>

            <div className="m-2 border border-black">
              <AcademicsAnswer
                setMcqAnswer={(value: any) => storeMcqAnswer(value)}
                getData={props?.getData}
              />
            </div>
          </div>
        ) : null}
      </div>
      {questionType === 2 || questionType === 4 ||questionType === 5|| questionType === 6 ? (
        <>
          <div className="mt-2 border border-black">
            <div className="m-2 border border-black">
              <div className="p-3 bg-dark d-flex align-items-center justify-content-between  mx-2 my-2">
                <h5 className="fw-bold text-light">Question Data</h5>
                <div className=" d-flex">
                  <div
                    className="cursor addInputbtn ms-2 p-1"
                    onClick={(id: any) => showInputMenu("text")}
                  >
                    + Text
                  </div>
                  <div
                    className="cursor addInputbtn ms-2 p-1"
                    onClick={(id: any) => showInputMenu("image")}
                  >
                    + Image
                  </div>
                  <div
                    className="cursor addInputbtn ms-2 p-1"
                    onClick={(id: any) => showInputMenu("video")}
                  >
                    + Video
                  </div>
                </div>
              </div>
              {renderClassContent()}
            </div>
            {showFixedQue &&
              fixedAnswer?.map((item: any, index: any) => {
                const { descType, description, answerType } = item;
                return (
                  <div className="m-2 border border-black">
                    <div
                      key={index}
                      className={`mb-1 px-3 py-2 border rounded`}
                    >
                      <label className="fw-bold mb-2">Answer</label>
                      <div className="d-flex mb-2">
                        <div className="me-3">
                          <input
                            type="radio"
                            name="fixedInput"
                            onChange={(e: any) =>
                              onChangeFixedAnswer(index, 1, "answerType")
                            }
                            checked={answerType === 1}
                          />{" "}
                          <span>Text</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="fixedInput"
                            onChange={(e: any) =>
                              onChangeFixedAnswer(index, 2, "answerType")
                            }
                            checked={answerType === 2}
                          />{" "}
                          <span>Equation</span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="col-md-6" style={{ marginLeft: "1px" }}>
                          <textarea
                            className="form-control cursor"
                            value={description}
                            onChange={(e: any) =>
                              onChangeFixedAnswer(
                                index,
                                e.target.value,
                                "description"
                              )
                            }
                            style={{ height: "100px" }}
                          />
                        </div>
                        <div className="col-md-6" style={{ marginLeft: "5px" }}>
                          {answerType === 2 && description ? (
                            <MathJaxContext config={config}>
                              <MathJax dynamic>
                                <h6
                                  className="d-flex align-items-center justify-content-center border rounded"
                                  style={{ height: "100px", overflow: "auto" }}
                                >{`$${description}$`}</h6>
                              </MathJax>{" "}
                            </MathJaxContext>
                          ) : (
                            <div>
                              <h6
                                className="d-flex align-items-center justify-content-center border rounded"
                                style={{ height: "100px", overflow: "auto" }}
                              >{`${description}`}</h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {questionType === 5?
              <div className="row mx-2 border border-black ">
                <div className="col-md-6">
                  <div className="p-2">
                    <div className="fw-bold text-center">Part A</div>
                    <div className="d-flex justify-content-center my-2">
                      <div
                        className="cursor border border-black  ms-2 p-1 px-2"
                        onClick={() => showDNDInputMenu("text",'partA')}
                      >
                        + Text
                      </div>
                      <div
                        className="cursor border border-black ms-2 p-1 px-2"
                        onClick={() => showDNDInputMenu("image",'partA')}
                      >
                        + Image
                      </div>
                    </div>
                    <div>
                      {renderDnDPart(dndPartAData,'partA')}
                    </div>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="p-2">
                    <div className="fw-bold text-center">Part B</div>
                    <div className="d-flex justify-content-center my-2">
                      <div
                        className="cursor border border-black  ms-2 p-1 px-2"
                        onClick={() => showDNDInputMenu("text",'partB')}
                      >
                        + Text
                      </div>
                      <div
                        className="cursor border border-black ms-2 p-1 px-2"
                        onClick={() => showDNDInputMenu("image",'partB')}
                      >
                        + Image
                      </div>
                    </div>
                    <div>
                      {renderDnDPart(dndPartBData,'partB')}
                    </div>
                  </div>

                </div>

              </div>
            :null}

            <div className="m-2 border border-black">
              <AcademicsHint
                setMcqHint={(value: any) => storeMcqHint(value)}
                getData={props?.getData}
              />
            </div>

            <div className="m-2 border border-black">
              <AcademicsAnswer
                setMcqAnswer={(value: any) => storeMcqAnswer(value)}
                getData={props?.getData}
              />
            </div>
          </div>
        </>
      ) : null}

      {showMSQ === true ? (
        <div className="mt-2 border border-black">
          <div className="m-2 border border-black">
            <div className="p-3 bg-dark d-flex align-items-center justify-content-between mt-2 mx-2">
              <h5 className="fw-bold text-light">Question Data</h5>
              <div className=" d-flex">
                <div
                  className="cursor addInputbtn ms-2 p-1"
                  onClick={(id: any) => showInputMenu("text")}
                >
                  + Text
                </div>
                <div
                  className="cursor addInputbtn ms-2 p-1"
                  onClick={(id: any) => showInputMenu("image")}
                >
                  + Image
                </div>
                <div
                  className="cursor addInputbtn ms-2 p-1"
                  onClick={(id: any) => showInputMenu("video")}
                >
                  + Video
                </div>
              </div>
            </div>
            {renderClassContent()}

            <div className="mx-2 mb-2 border">
              <div>{renderMsqOption()}</div>
              {optionArr.length < 4 ? (
                <div className="d-flex justify-content-end m-2">
                  <Addmorebtn name="Add New Option" handleClick={onPressAdd} />
                </div>
              ) : null}
            </div>
          </div>

          <div className="m-2 border border-black">
            <AcademicsHint
              setMcqHint={(value: any) => storeMcqHint(value)}
              getData={props?.getData}
            />
          </div>

          <div className="m-2 border border-black">
            <AcademicsAnswer
              setMcqAnswer={(value: any) => storeMcqAnswer(value)}
              getData={props?.getData}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
