import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import { useDispatch } from "react-redux";
import {
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
} from "../../Redux/Actions/AdminAction";
import { GradeList } from "../../Utils/dataConstant";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import InputField from "../../Atoms/InputField";
import { length_One, length_Three } from "../../Utils/validators";

export default function QuestionConfig() {
  const dispatch: Function = useDispatch();
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [toggle, setToggle] = useState<any>(false);

  const initialVal: {
    level: any;
    percentage: any;
  }[] = [
      {
        level: "",
        percentage: "",
      },
    ];
  const [questionFormat, setQuestionFormat] = useState(initialVal);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value._id);
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
  };

  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const onChangeQueFormat = (val: any) => {

  }

  const renderData = () => {
    return (
      <>
        <div className="row px-2 mx-0 mt-4 ">
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Country"} />
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Course Type"} />
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourseType(value)}
                value={course}
                options={courseList}
                reset={resetParentCourse}
              />
            </div>
          </div>
          {course?.type === 2 ? (
            <div className="col-sm-3">
              <div className="d-flex mb-2 align-items-center">
                <LabelField lableName={"Student Grade"} />
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  options={GradeList}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {course?.type === 2 || course?.type === 3 ? null : (
            <div className="col-sm-3">
              <div className="d-flex mb-2 align-items-center">
                <LabelField lableName={"Exam Name "} />
                <CustomDropdown
                  lableName="Select Exam Name"
                  value={exam}
                  options={examList}
                  reset={resetExam}
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                />
              </div>
            </div>
          )}
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Subject Name"} />
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                reset={resetSubject}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Active"} />
              <div className="ms-2 mt-3">
                <ToggleSwitchbtn
                  getToggleval={(value: any) => {
                    changeToggle(value);
                  }}
                  isActive={toggle}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row m-3 border border-dark py-3">
          <div className="col-md-12">
            <h4 className="mb-3">{"Question Format"}</h4>
            <div className="d-flex justify-content-between mb-3">
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center">
                  <LabelField lableName={"Complex"} />
                </div>
                <div className="col-8 ">
                  <InputField
                    placeholder="Enter Question Complex Percentage"
                    value={1}
                    onChangeInput={(value: any) => onChangeQueFormat(value)}

                    isRequired={true}
                  />
                </div>
              </div>
            </div>


          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE QUESTION CONFIG"} />
      <div className="formDiv">
        <form className="text-center" onSubmit={handleSubmit}>
          <div className="col-auto">{renderData()}</div>
        </form>
      </div>
    </div>
  );
}
