import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Components/customDropdown";
import { PaymentTypes } from "../../Utils/dataConstant";
import { getPaymentDetails } from "../../Redux/Actions/AdminAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import AdminTable from "../../Components/adminTable";

export default function CounsellorPayments() {
  const [type, setType] = useState<any>("");
  const [resetTypeList, setResetTypeList] = useState(false);
  const [counsellorPayList, setCounsellorPayList] = useState<any>([]);
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      counsellorId: userId,
    };
    dispatch(
      getPaymentDetails(
        postdata,
        (res: any) => {
          setCounsellorPayList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      type: type?._id,
      counsellorId: userId,
    };
    dispatch(
      getPaymentDetails(
        postdata,
        (res: any) => {
          setCounsellorPayList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeType = (value: any) => {
    setResetTypeList(false);
    setType(value);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"COUNSELLOR PAYMENTS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="d-flex flex-wrap  mt-3">
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Type"} />
              <div className="col-md-8 ">
                <CustomDropdown
                  lableName="Select Type"
                  setInputText={(value: any) => onChangeType(value)}
                  value={type}
                  options={PaymentTypes}
                  Isrequired={true}
                  reset={resetTypeList}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <Submitbtn name={"Search"} />
            </div>
          </div>
          <div className="row">
            <>
              <AdminTable
                tableData={counsellorPayList ? counsellorPayList : []}
                pagename={"counsellorPayments"}
                tableHead={[
                  "S.N",
                  "NAME",
                  "COUNTRY NAME",
                  "COURSETYPE NAME",
                  "PACKAGE NAME",
                  "COUPON NAME",
                  "PAYMENT DATE",
                ]}
              />
            </>
          </div>
        </form>
      </div>
    </div>
  );
}
