import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import { today } from "../../Utils/appConstants";
import SubmitButton from "../../Components/searchButton";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Components/customDropdown";
import {
  AffiliatePaymentList,
  AffiliatePayoutSave,
  getCountryList,
  getCourseTypelist,
} from "../../Redux/Actions/AdminAction";
import moment from "moment";
import { getAffiliateUsers } from "../../Redux/Actions/SuperAdminAction";

export default function AffiliatePaymentPayout() {
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [payoutList, setPayoutList] = useState<any>([]);
  const [selectPayment, setSelectPayment] = useState<any>([]);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [affiliateUsersList, setAffiliateUsersList] = useState([]);
  const [affiliateUser, setAffiliateUser] = useState<any>([]);
  const [resetAffiliateUserList, setResetAffiliateUserList] = useState(false);
  const [toggle, setToggle] = useState("Pending");

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    const data = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      AffiliatePaymentList(
        data,
        (res: any) => {
          if (toggle === "Pending") {
            let unpaidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status !== "paid"
            );
            setPayoutList(unpaidList);
          } else if (toggle === "Paid") {
            let paidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status === "paid"
            );
            setPayoutList(paidList);
          }
        },
        () => {}
      )
    );

    const postData: any = {
      departmentId: 18,
      userRoleId: 20,
    };

    dispatch(
      getAffiliateUsers(
        postData,
        (res: any) => {
          setAffiliateUsersList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const tab = [
    {
      _id: "1",
      name: "Pending",
    },
    {
      _id: "2",
      name: "Paid",
    },
  ];

  const handleChangetab = (val: any) => {
    setToggle(val);
    if (val === "Pending") {
      const data = {
        startDate: startDate,
        endDate: endDate,
        type: "LIVE",
        countryId: country?._id,
        coursetypeId: course?._id,
        refId: affiliateUser?.empId,
      };
      dispatch(
        AffiliatePaymentList(
          data,
          (res: any) => {
            let unpaidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status !== "paid"
            );
            setPayoutList(unpaidList);
          },
          () => {}
        )
      );
    } else if (val === "Paid") {
      const data = {
        startDate: startDate,
        endDate: endDate,
        type: "LIVE",
        countryId: country?._id,
        coursetypeId: course?._id,
        refId: affiliateUser?.empId,
      };
      dispatch(
        AffiliatePaymentList(
          data,
          (res: any) => {
            let paidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status === "paid"
            );
            setPayoutList(paidList);
          },
          () => {}
        )
      );
    }
  };

  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };
  const onChangeCountry = (value: any) => {
    setCountry(value);
    setResetCountryList(false);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
  };

  const onChangeAffiliateUser = (value: any) => {
    setAffiliateUser(value);
  };

  const handleOpenPayPopUp = (value: any) => {
    let selectedCheckboxes = payoutList.filter(
      (elm: any, idx: any) => elm.isSelected === true
    );
    const postdata = {
      payout: selectedCheckboxes,
    };
    dispatch(
      AffiliatePayoutSave(
        postdata,
        (res: any) => {
          reloadList();
        },
        () => {}
      )
    );
  };

  const handleCheck = (value: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(payoutList));
    if (value.target.checked) {
      newArray[index]["isSelected"] = true;
      setSelectPayment(newArray);
      setPayoutList(newArray);
    } else {
      newArray[index]["isSelected"] = false;
      setPayoutList(newArray);
      setSelectPayment([]);
    }
  };
  console.log("toggle", toggle);
  const renderListMode = () => {
    return (
      <div className="p-2 table-responsive">
        <div className="col-md-12 p-2 border-end ">
          <div className="d-flex mt-5  border-bottom  ">
            {tab?.map((c, index) => (
              <div
                className={`col-md-4 fs-6 text-center cursor  ${
                  toggle === c.name
                    ? " text-primary border-bottom border-primary border-3 fw-bold"
                    : "text-secondary fw-bold"
                }`}
                onClick={() => handleChangetab(c.name)}
              >
                {c.name}
              </div>
            ))}
          </div>
        </div>
        <table className="table table-bordered text-center ">
          <thead className="table-primary ">
            <tr>
              <th className="p-3 ">S.N</th>
              <th className="p-3 ">Name</th>
              <th className="p-3 ">Student Name</th>
              <th className="p-3 ">Purchase Date</th>
              <th className="p-3 "> Per Refferal Charges</th>
              <th className="p-3 "> Amount Paid</th>
              <th className="p-3 ">Pay</th>
            </tr>
          </thead>
          {payoutList.length > 0 ? (
            <tbody>
              {payoutList?.map((value: any, index: any) => {
                return (
                  <>
                    <tr>
                      <div className="d-flex">
                        {value?.status === "paid" ? null : (
                          <td>
                            <span>
                              <input
                                className="check"
                                type="checkbox"
                                checked={value.isSelected}
                                onChange={(value) => {
                                  handleCheck(value, index);
                                }}
                              />
                            </span>
                          </td>
                        )}
                        <td>{index + 1}</td>
                      </div>

                      <td>{value?.affiliateUserName}</td>
                      <td className="text-start"> {value?.studentName}</td>
                      <td>
                        {moment(value?.purchaseDate).format("DD-MM-YYYY")}
                      </td>

                      <td>{value?.amount ? value?.amount : "-"}</td>
                      <td>
                        {value?.paidAmount
                          ? parseFloat(value?.paidAmount).toFixed(2)
                          : "-"}
                      </td>
                      <td>
                        {value?.status === "paid" ? value?.status : "unpaid"}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <td className="fw-bold p-1">No Record Found</td>
            </tbody>
          )}
        </table>
        {payoutList.length > 0 && toggle === "Pending" ? (
          <div className="d-flex justify-content-end">
            <button
              className={
                selectPayment?.length > 0
                  ? "btn btn-success "
                  : " btn btn-secondary"
              }
              onClick={(value) => handleOpenPayPopUp(value)}
            >
              Submit
            </button>
          </div>
        ) : null}
      </div>
    );
  };
  const reloadList = () => {
    const data = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      AffiliatePaymentList(
        data,
        (res: any) => {
          if (toggle === "Pending") {
            let unpaidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status !== "paid"
            );
            setPayoutList(unpaidList);
          } else if (toggle === "Paid") {
            let paidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status === "paid"
            );
            setPayoutList(paidList);
          }
        },
        () => {}
      )
    );
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const data = {
      startDate: startDate,
      endDate: endDate,
      countryId: country?._id,
      coursetypeId: course?._id,
      refId: affiliateUser?.empId,
    };
    dispatch(
      AffiliatePaymentList(
        data,
        (res: any) => {
          if (toggle === "Pending") {
            let unpaidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status !== "paid"
            );
            setPayoutList(unpaidList);
          } else if (toggle === "Paid") {
            let paidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status === "paid"
            );
            setPayoutList(paidList);
          }
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"PAYMENT PAYOUT"} />
      <div className="formDiv">
        <form className="text-center " onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex flex-wrap Justify-content-between ">
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Start Date:"} />
              <div className="col-7 ps-2 ">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => onChangeStartDate(value)}
                  maxdate={today}
                />
              </div>
            </div>
            <div className=" col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"End Date:"} />
              <div className="col-7 ps-2 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                  maxdate={today}
                />
              </div>
            </div>
            <div className=" col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Country"} />
              <div className="col-8 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className=" col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Course Type"} />
              <div className="col-8 ps-2 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            <div className=" col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Affiliate Users"} />
              <div className="col-8 ps-2 ">
                <CustomDropdown
                  lableName="Select Affiliate User"
                  setInputText={(value: any) => onChangeAffiliateUser(value)}
                  value={affiliateUser}
                  options={affiliateUsersList}
                  reset={resetAffiliateUserList}
                />
              </div>
            </div>

            <div className=" col-md-3 d-flex mb-2 ">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
        <div className="shadow-lg  m-2 mt-5  ">{renderListMode()}</div>
      </div>
    </div>
  );
}
