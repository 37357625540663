import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import affiliateSidebarData from "./affiliateSidebarData";
import UserProfile from "./userProfile";
import AffiliateLeads from "./affiliateLeads";
import DemoPayments from "./demoPayments";


export default function AffiliatePanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={affiliateSidebarData?.AffiliateSidebarData} />

      <div className="dashboard">
        <Routes>
          <Route path="/" element={<UserProfile />} />
          <Route path="userProfile" element={<UserProfile />} />
          <Route path="affiliateLeads" element={<AffiliateLeads />} />
          <Route path="demoPayments" element={<DemoPayments />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
