import React, { useState,useRef, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SubmitButton from "./submitButton";
import {
  ReScheduleDemo,
  deleteRescheduleReq,
} from "../Redux/Actions/AdminAction";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "./customDropdown";
import LabelField from "./labelField";
import { cancelReasonList } from "../Utils/dataConstant";
import { imgPreview } from "../Screens/Affiliate/imgPreview";
import ReactCrop, {
  type Crop,
  centerCrop,
  makeAspectCrop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import { useDebounceEffect } from "../Hooks/useDebounceEffect";
interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  getList?: any;
}

export default function CreateBannerPopup(props: props) {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const [reason, setReason] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [reasonList, setReasonList] = useState<any>(cancelReasonList);
  const [resetReason, setResetReason] = useState<any>(false); 
  const [sname, setName] = useState("");
  const [cropprofileImg, setCropProfileImg] = useState<any>("");
  const canvasRef = useRef(null); 
  const [profileImg, setProfileImg] = useState<any>("");
  const [crop, setCrop] = useState<Crop>();
  const [openImgcropPopup, setopenImgcropPopup] = useState(false);  
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const { open, handleClose, data, getList } = props; 
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(4 / 5);
  const imgRef = useRef<HTMLImageElement>(null);
  const hiddenFileInput: any = useRef(null); 
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    console.log("event",event)
    const reader = new FileReader();
    setCrop(undefined);
    const file = event.target.files[0];
    reader.onloadend = () => {
      setProfileImg(reader.result);
    };
    reader.readAsDataURL(file);
    setopenImgcropPopup(true);
  };
  const handleDownload = () => {
    const canvas: any = canvasRef.current;
    const link = document.createElement("a");
    link.download = "canvas-image.png";
    link.href = canvas.toDataURL();
    link.click();
  };
  // useEffect(() => {
  //   drawCanvas();
  // }, [sname, cropprofileImg]);

  // const drawCanvas = () => {
    // const canvas: any = canvasRef.current;
    // const ctx = canvas.getContext("2d");
    // const img = new Image();

    // Load the main invitation card image
    // img.onload = () => {
    //   canvas.width = img.width;
    //   canvas.height = img.height;
    //   ctx.drawImage(img, 0, 0);

      // // Load the profile image
      // if (cropprofileImg) {
      //   const profileImgElement = new Image();
      //   profileImgElement.onload = () => {
      //     ctx.drawImage(profileImgElement, 39, 534, 330, 404);

      //     // Draw text
      //     ctx.font = "bold 26px Poppins";
      //     ctx.textAlign = "center";
      //     ctx.fillStyle = "black";
      //     ctx.fillText(sname, 200, 1000); // Adjust the position as needed
      //   };
      //   profileImgElement.src = cropprofileImg; // Use profileImg as the source
      // } else {
      //   // Draw text if no profile image is selected
      //   ctx.font = "bold 26px Poppins";
      //   ctx.textAlign = "center";
      //   ctx.fillStyle = "black";
      //   ctx.fillText(sname, 200, 1000); // Adjust the position as needed
      // }
  //   };

  //   img.src = data;
  // };
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) 
      {
        // We use canvasPreview as it's much faster than imgPreview.
        imgPreview(
          imgRef.current,
          // previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        console.log(
          "image",
          imgPreview(
            imgRef.current,
            // previewCanvasRef.current,
            completedCrop,
            scale,
            rotate
          ).then((res:any) => {
            setCropProfileImg(res);
          })
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
 console.log("usersDetails?.bannerimage",data)

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      // onClose={() => {
      //   handleClose();
      // }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="bannerPopup ">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">Create Banner</h5>

            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>

          <div className="row d-flex justify-content-between mt-2">
            <div className="col-md-6">
            <img
                  src={data}
                  alt="cardImg"
                  className="img-fluid"
                />
            </div>
            <div className="col-md-6">

            <div className="col-md-5">
              <div className="my-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label fw-bold"
                >
                  अपना नाम लिखें
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Your Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-success w-100"
                  onClick={handleClick}
                >
                  <UploadIcon /> अपनी फोटो अपलोड करें
                </button>
                <input
                  type="file"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />
              </div>
              <div className="mt-3 text-center">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDownload}
                >
                  <DownloadIcon /> डाउनलोड और शेयर करें
                </button>
              </div>
              {/* <div>
                <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} minHeight={100} onComplete={(c) => setCompletedCrop(c)} locked={true}>
                  {profileImg ? <img src={profileImg} alt='cardImg' className='' style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                    onLoad={onImageLoad} ref={imgRef} /> : null}
                </ReactCrop>
              </div> */}
              {/* {!!completedCrop && (
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                      display: "none",
                    }}
                  />
                </div>
              )} */}
            </div>
            </div>
            

          </div>
        </div>
      </Fade>
    </Modal>
  );
}
 

