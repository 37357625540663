import { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import CustomDropdown from "../../Components/customDropdown";
import { GradeList, defaultDuration } from "../../Utils/dataConstant";
import {
  addExamSet,
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getExamSetTypeId,
  getFilteredPatternList,
  getGeneralExamList,
  getLectureList,
  getSessionList,
  getSubTopicList,
  getTopicList,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import Submitbtn from "../../Components/submitButton";
import {
  getGeneralPackageList,
  getPackageSessionList,
} from "../../Redux/Actions/SuperAdminAction";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import TimerClock from "../../Atoms/timerClock";
import { length_Hundred, validateSubject } from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import DateTimePicker from "../../Components/dateTimePicker";

export default function CreateExamSet() {
  const dispatch: Function = useDispatch();
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [topic, setTopic] = useState<any>("");
  const [topicList, setTopicList] = useState([]);
  const [resetTopic, setResetTopic] = useState(false);
  const [subTopic, setSubTopic] = useState<any>("");
  const [subTopicList, setSubTopicList] = useState([]);
  const [resetSubTopic, setResetSubtopic] = useState(false);
  const [pattern, setPattern] = useState<any>("");
  const [patternList, setPatternList] = useState([]);
  const [resetPattern, setResetPattern] = useState(false);
  const [dppName, setDppName] = useState<any>("");
  const [duration, setDuration] = useState<any>(defaultDuration);
  const [batch, setBatch] = useState<any>("");
  const [batchList, setBatchList] = useState([]);
  const [resetBatch, setResetBatch] = useState(false);
  const [examSetName, setExamSetName] = useState<any>("");
  const [examNumber, setExamNumber] = useState<string>("");
  const [examSetIdList, setExamSetIdList] = useState<any>([]);
  const [examSetId, setExamSetId] = useState<any>(1);
  const [toggle, setToggle] = useState(false);

  const [session, setSession] = useState<any>("");
  const [sessionList, setSessionList] = useState<any>([]);
  const [resetSession, setResetSession] = useState(false);

  const [startdate, setStartdate] = useState<any>("");
  const [enddate, setEnddate] = useState<any>("");

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);

  const [lecture, setLecture] = useState<any>("");
  const [lectureList, setLectureList] = useState([]);
  const [resetLectureList, setResetLectureList] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );

    dispatch(
      getExamSetTypeId(
        {},
        (res: any) => {
          setExamSetIdList(res);
          setExamSetId(res[0]?._id);
        },
        () => { }
      )
    );

    dispatch(
      getFilteredPatternList(
        {},
        (res: any) => {
          setPatternList(res);
        },
        () => { }
      )
    );

    return;
  }, []);

  const onchangeExamSet = (c: any) => {
    setExamSetId(c);
    clearData();
    dispatch(
      getFilteredPatternList(
        {},
        (res: any) => {
          setPatternList(res);
        },
        () => { }
      )
    );

  };

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
          setResetCourseType(false);
          setResetExam(true);
          setResetSubject(true);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );

  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );

  };
  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    setResetExam(false);
    const postdata = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
    };
    dispatch(
      bindSubjectList(
        postdata,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
    if (examSetId === 2 || examSetId === 3) {
      dispatch(
        getGeneralPackageList(
          {
            courseId: courseType?._id,
            examtypeId: value._id,
          },
          (res: any) => {
            setBatchList(res);
          },
          () => { }
        )
      );
    }


  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: value?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSession = (value: any) => {
    setSession(value._id);
    setResetSession(false);
  };

  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value._id);

    const data: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: subject,
      topicId: value._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setSubTopicList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubTopic = (value: { _id: any; name?: string }) => {
    setSubTopic(value._id);
    const postdata: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: subject,
      levelId: "",
      topicId: topic,
      subTopicId: subTopic,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["examtypeId"] === null ||
      postdata["examtypeId"] === undefined ||
      postdata["examtypeId"] === ""
    ) {
      delete postdata["examtypeId"];
    }
    if (
      postdata["levelId"] === null ||
      postdata["levelId"] === undefined ||
      postdata["levelId"] === ""
    ) {
      delete postdata["levelId"];
    }
    if (
      postdata["topicId"] === null ||
      postdata["topicId"] === undefined ||
      postdata["topicId"] === ""
    ) {
      delete postdata["topicId"];
    }
    if (
      postdata["subTopicId"] === null ||
      postdata["subTopicId"] === undefined ||
      postdata["subTopicId"] === ""
    ) {
      delete postdata["subTopicId"];
    }
    dispatch(
      getLectureList(
        postdata,
        (res: any) => {
          setLectureList(res);
        },
        () => { }
      )
    );
  };
  const onChangeLecture = (value: { _id: any; name?: string }) => {
    setLecture(value._id);
  };
  const onChangePattern = (value: { _id: any; name?: string }) => {
    setPattern(value._id);
    setResetPattern(false);
  };
  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const onChangeBatch = (value: any) => {
    setBatch(value._id);
    setResetBatch(false);
    dispatch(
      getPackageSessionList(
        {
          packageIds: [value._id],
        },
        (res: any) => {
          setSessionList(res);
        },
        () => { }
      )
    );
    dispatch(
      getSessionList(
        {},
        (res: any) => {
          setSessionList(res);
        },
        () => { }
      )
    );
  };

  const onchangeDpp = (value: any) => {
    if (validateSubject(value)) {
      setDppName(value);
    } else {
      dispatch(showErrorSnackbar(" please enter alphabets & digits only"));
      return;
    }
  };

  const resetForm = () => {
    setResetCountryList(true);
    setResetCourseType(true);
    setResetExam(true);
    setResetGradeList(true);
    setExam("");
    setExamList([]);
    setResetSubject(true);
    setSubjectList([]);
    setResetTopic(true);
    setSubTopicList([]);
    setResetSubtopic(true);
    setSubTopicList([]);
    setResetPattern(true);
    setPatternList([]);
    setDppName("");
    setDuration(defaultDuration);
    setResetBatch(true);
    setBatchList([]);
    setResetSession(true);
    setSessionList([]);
    setExamSetName("");
    setExamNumber("");
    setStartdate("");
    setEnddate("");
    setResetLectureList(true)
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el: any) => (el.checked = false));
  };

  const clearData = () => {
    setResetCountryList(true);
    setResetCourseType(true);
    setResetExam(true);
    setExamList([]);
    setExam("");
    setResetSubject(true);
    setSubjectList([]);
    setResetTopic(true);
    setSubTopicList([]);
    setResetSubtopic(true);
    setSubTopicList([]);
    setResetPattern(true);
    setPatternList([]);
    setDppName("");
    setDuration(defaultDuration);
    setResetBatch(true);
    setBatchList([]);
    setResetSession(true);
    setSessionList([]);
    setExamSetName("");
    setExamNumber("");
    setStartdate("");
    setEnddate("");
    setResetLectureList(true)
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el: any) => (el.checked = false));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let arr = [];
    arr.push(duration.split(":"));
    var examDuration = arr[0][0] * 60 + +arr[0][1] + arr[0][2] / 60;

    const postData: any = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      gradeId: grade?._id,
      subjectId: subject,
      topicId: topic,
      subTopicId: subTopic,
      packageId: batch,
      sessionId: session,
      examPatternId: pattern,
      modelSetPaper: examSetId === 3 || examSetId === 4 ? true : false,
      DPP_Name: dppName,
      examSetName: examSetName,
      examSetNumber: parseInt(examNumber),
      // sessionTime
      isActive: toggle,
      examSetTypeId: examSetId,
      examDuration: examDuration,
      startDate: startdate,
      endDate: enddate,
      lectureId: lecture,
    };
    if (exam === "") delete postData["examtypeId"];
    if (grade === "") delete postData["gradeId"];
    if (subject === "") delete postData["subjectId"];
    if (topic === "") delete postData["topicId"];
    if (subTopic === "") delete postData["subTopicId"];
    if (batch === "") delete postData["packageId"];
    if (session === "") delete postData["sessionId"];
    if (pattern === "") delete postData["examPatternId"];
    if (dppName === "") delete postData["DPP_Name"];
    if (examNumber === "") delete postData["examSetNumber"];
    if (examSetId === "") delete postData["examSetTypeId"];
    if (startdate === "") delete postData["startDate"];
    if (enddate === "") delete postData["endDate"];
    if (examSetName === "") delete postData["examSetName"];
    if (lecture === "") delete postData["lectureId"];

    dispatch(
      addExamSet(
        postData,
        (res: any) => {
          resetForm();
        },
        () => { }
      )
    );
  };

  const validation = () => {
    return (
      courseType === "" ||
      // subject === "" ||
      (examSetId === 1 && subject === "") ||
      duration === defaultDuration ||
      (examSetId === 1 && topic === "") ||
      (examSetId === 1 && subTopic === "") ||
      ((examSetId === 2 || examSetId === 3) && batch === "") ||
      ((examSetId === 1 || examSetId === 2) && pattern === "") ||
      (examSetId === 1 &&
        (dppName === "" || dppName.length > length_Hundred - 1)) ||
      (examSetId !== 1 && examSetName === "") ||
      (examSetId !== 1 && examNumber === "") ||
      (examSetId === 4 && startdate === "") ||
      (examSetId === 4 && enddate === "")
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE EXAM SET"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="col-12" onSubmit={handleSubmit}>
          <div className="col-10">
            <div className="d-flex Justify-content-between mb-3">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Flag "} />
              </div>
              {examSetIdList.map((c: any, index: any) => {
                return (
                  <div className="col-2">
                    <span className="me-3 radiobtnspan">
                      <span>
                        <input
                          type="radio"
                          name="type"
                          id={c._id}
                          checked={c._id === examSetId ? true : false}
                          onChange={(e) => {
                            // setExamSetId(c?._id);
                            // clearData();
                            onchangeExamSet(c._id);
                          }}
                        />{" "}
                        <span className="ms-2">{c?.name}</span>
                      </span>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>

            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-2  ">
                <CustomDropdown
                  lableName="Select Course Type"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeCourseType(value)
                  }
                  value={courseType}
                  options={courseTypeList}
                  Isrequired={true}
                  reset={resetCourseType}
                />
              </div>
            </div>
            {courseType?.type === 2 ? (
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Student Grade"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Student Grade"
                    setInputText={(value: any) => onChangeStudentGrade(value)}
                    value={grade}
                    Isrequired={true}
                    options={GradeList}
                    reset={resetGradeList}
                  />
                </div>
              </div>
            ) : null}
            {courseType?.type === 2 || courseType?.type === 3 ? null : (
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                  />
                </div>
              </div>
            )}

            <>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Subject Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubject(value)
                    }
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    reset={resetSubject}
                  />
                </div>
              </div>
            </>


            {/* *******************************************FOR DPP********************************************* */}
            {examSetId === 1 ? (
              <>
                {" "}
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Topic Name "} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Topic Name "
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeTopic(value)
                      }
                      value={topic}
                      options={topicList}
                      Isrequired={true}
                      reset={resetTopic}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Sub-Topic Name "} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Sub-Topic Name "
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeSubTopic(value)
                      }
                      value={subTopic}
                      options={subTopicList}
                      Isrequired={true}
                      reset={resetSubTopic}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Lecture Name "} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Topic Name "
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeLecture(value)
                      }
                      value={lecture}
                      options={lectureList}
                      Isrequired={true}
                      reset={resetLectureList}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {/* ***********************************************FOR UNIT TEST*********************************************** */}
            {examSetId === 2 || examSetId === 3 ? (
              <>
                <div className="d-flex Justify-content-between mt-3">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Batch Name"} />
                  </div>

                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Batch Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeBatch(value)
                      }
                      value={batch}
                      options={batchList}
                      Isrequired={true}
                      reset={resetBatch}
                    />
                  </div>
                </div>

                {batchList.length > 0 && sessionList?.length > 0 ? (
                  <div className="d-flex Justify-content-between mt-3">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Batch Session"} />
                    </div>

                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Session"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeSession(value)
                        }
                        value={session}
                        options={sessionList}
                        defaultValue={session}
                        reset={resetSession}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              ""
            )}

            {examSetId === 1 || examSetId === 2 ? (
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Pattern Name  "} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Pattern Name "
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangePattern(value)
                    }
                    value={pattern}
                    options={patternList}
                    Isrequired={true}
                    reset={resetPattern}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {examSetId === 1 ? (
              <div className="d-flex Justify-content-between mt-3">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"DPP Name "} />
                </div>

                <div className="col-6 ">
                  <InputField
                    placeholder="Enter DPP Name "
                    value={dppName}
                    onChangeInput={(value: any) => onchangeDpp(value)}
                    maxlength={50}
                    minlength={3}
                    isRequired
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex Justify-content-between mt-2">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Model Set Paper"} />
              </div>
              <div className="col-6 ps-2 ">
                <p>{examSetId === 3 || examSetId === 4 ? "Yes" : "No"}</p>
              </div>
            </div>
            {examSetId === 2 || examSetId === 3 || examSetId === 4 ? (
              <>
                <div className="d-flex Justify-content-between ">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Exam Set Name "} />
                  </div>

                  <div className="col-6 ">
                    <InputField
                      placeholder="Enter Exam Set Name "
                      value={examSetName}
                      onChangeInput={(value: any) => setExamSetName(value)}
                      maxlength={100}
                      isRequired
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between mt-3">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Exam Display Number"} />
                  </div>

                  <div className="col-6 ">
                    <InputField
                      placeholder="Enter Exam Display Number "
                      value={examNumber}
                      onChangeInput={(value: any) => setExamNumber(value)}
                      maxlength={10}
                      isRequired
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {examSetId === 4 ? (
              <div className="d-flex">
                <div className="d-flex Justify-content-between col-6">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Start Date"} />
                  </div>
                  <div className="col-6 d-flex ">
                    <div>
                      <DateTimePicker
                        value={startdate}
                        setInputText={(value: string) => setStartdate(value)}
                        Isrequired={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex Justify-content-between col-6">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"End Date"} />
                  </div>
                  <div className="col-6 d-flex ">
                    <div>
                      <DateTimePicker
                        value={enddate}
                        setInputText={(value: string) => setEnddate(value)}
                        Isrequired={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="">
              <ToggleSwitchbtn
                lableName="Active"
                getToggleval={(value: any) => {
                  changeToggle(value);
                }}
              />
            </div>
            <div className="d-flex Justify-content-between mt-2">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Duration "} />
              </div>
              <div className="col-6  ">
                <TimerClock
                  value={duration}
                  Isrequired={true}
                  setInputText={(value: any) => setDuration(value)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Create Exam Set"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
