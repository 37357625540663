export default {
  HrOperationSidebarData: [
    {
      title: "TEACHER",
      subNav: [
        {
          title: "CREATE",
          path: "createTeacher",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "UpdateTeacher ",
          cName: "sub-nav",
        },
      ],
    },




  ]
};