import React, { useState } from "react";
import InputField from "../../Atoms/InputField";
import Submitbtn from "../../Atoms/submitbtn";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { superAdminLogin } from "../../Redux/Actions/AuthAction";
import jwt_decode from "jwt-decode";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";

export default function Login() {
  const navigate = useNavigate();
  const dispatch: Function = useDispatch();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string | number>("");

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      username: userName,
      password: password,
    };
    dispatch(
      superAdminLogin(
        postData,
        (res: any) => {
          if (res.token) {
            var decoded: any = jwt_decode(res.token.access.token);
            console?.log("decoded", decoded);
            if (decoded) {
              dispatch(updateCommonValues("userName", decoded?.name));
              dispatch(updateCommonValues("userId", decoded?._id));
              dispatch(updateCommonValues("countryId", decoded?.countryId));
              dispatch(updateCommonValues("userEmail", decoded?.email));
              if (decoded.role === 1) {
                navigate("../SuperAdminPanel");
              }
              if (decoded.role === 2) {
                navigate("../AdminPanel");
              } else if (decoded.role === 3) {
                navigate("../CouncellorPanel");
              } else if (decoded.role === 4) {
                navigate("../TeachersPanel");
              } else if (decoded.role === 5) {
                navigate("../DataEntrypanel");
              } else if (decoded.role === 6) {
                navigate("../SalesPanel");
              } else if (decoded.role === 8) {
                navigate("../InfraPanel");
              } else if (decoded.role === 9) {
                navigate("../Finance");
              } else if (decoded.role === 10) {
                navigate("../CXO");
              } else if (decoded.role === 11) {
                navigate("../Technical");
              } else if (decoded.role === 12) {
                navigate("../CategoryPanel");
              } else if (decoded.role === 13) {
                navigate("../HrOperationPanel");
              } else if (decoded.role === 14) {
                navigate("../OperationPanel");
              } else if (decoded.role === 15) {
                navigate("../Configuration");
              } else if (decoded.role === 16) {
                navigate("../DsaManager");
              } else if (decoded.role === 17) {
                navigate("../DsaSales");
              } else if (decoded.role === 18) {
                navigate("../Academics");
              } else if (decoded.role === 20) {
                navigate("../Affiliate");
              } else if (decoded.role === 21) {
                navigate("../DsaUser");
              }
            }
          }
        },
        () => {}
      )
    );
    //navigate("../CategoryPanel");
  };

  return (
    <div className="loginContainer">
      <form className="text-center" onSubmit={handleSubmit}>
        <h2>Lurnigo Admin</h2>
        <div>
          <InputField
            placeholder="Enter Your Name"
            value={userName}
            onChangeInput={(value: any) => setUserName(value)}
          />
        </div>
        <div>
          <InputField
            placeholder="Enter Password"
            value={password}
            onChangeInput={setPassword}
          />
        </div>
        <div className="col-auto d-flex justify-content-center mt-4">
          <Submitbtn name="Submit" />
        </div>
      </form>
    </div>
  );
}
