export default {
  CouncellorSidebarData: [
    {
      title: "Leads",
      subNav: [
        {
          title: "CREATE",
          path: "leads",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "leads",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "COUNSELLOR PAYMENTS",
      path: "counsellorPayments",

    },
  


 
   
  ]
};