/* eslint-disable react/prop-types */
import React from "react";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import DeleteIcon from "@mui/icons-material/Delete";

interface props {
  labelName?: string;
  setInputText: Function;
  options?: any;
  value?: any;
  disabled?: any;
}

function Dropdown({ labelName, setInputText, options, value, disabled }: any) {
  const onHandleChange = (e: any) => {
    if (e.target.value) {
      setInputText(JSON.parse(e.target.value));
    }
  };

  const onChangeClear = () => {
    setInputText("");
  };

  return (
    <div className="d-flex mb-2">
      <select
        className="form-select w-100 border rounded p-2"
        value={JSON.stringify(value)}
        onChange={(e) => {
          onHandleChange(e);
        }}
        disabled={disabled}
      >
        <option value="">{labelName}</option>
        {options?.length > 0
          ? options?.map((item: any) => {
            return (
              <option key={item?._id} value={JSON.stringify(item)}>
                {item?.name}
              </option>
            );
          })
          : null}
      </select>
      <button
        type="button"
        className={`ms-1 rounded border ${value && disabled
          ? " text-dark"
          : value
            ? "text-white"
            : ""
          }`}
        onClick={onChangeClear}
        disabled={disabled}
      >
        <span className="d-flex align-items-center">
          <DeleteIcon className="cursor" color="error" fontSize="small" />
        </span>
      </button>
    </div>
  );
}

export default React.memo(Dropdown);
