export default {
  AffiliateSidebarData: [
    {
      title: "MY PROFILE",
      path: "userProfile",
    },
    {
      title: "LEAD GENERATED",
      path: "affiliateLeads",
    },
    {
      title: "DEMO PAYMENTS",
      path: "demoPayments",
    },

  ],
};
