import { useEffect, useRef, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch } from "react-redux";
import { getAdminPanelUsers } from "../../Redux/Actions/SuperAdminAction";
import DatePicker from "../../Components/datePicker";
import CreateBannerPopup from "../../Components/createBannerPopup"; 
import html2canvas from 'html2canvas';
export default function UserProfile() {
  const dispatch: Function = useDispatch();
  const [usersDetails, setUsersDetails] = useState<any>();
  const [dateOfJoining, setDateOfJoining] = useState<string>("");
  const canvasRef = useRef(null); 
  const [openPopup, setOpenPopup] = useState(false);
  const containerRef = useRef(null);
  const { userName, userId } = useSelector(
    (state: { Auth: AuthReducerModal }) => ({
      userName: state.Auth.userName,
      userId: state.Auth.userId,
    })
  );
  useEffect(() => {
    const postData: any = {
      skip: 0,
      limit: 1,
      _id: userId,
      departmentId: 18,
      userRoleId: 20,
    };

    dispatch(
      getAdminPanelUsers(
        postData,
        (res: any) => {
          setUsersDetails(res?.data[0]);
        },
        () => {}
      )
    );
  }, []);

  async function handleDownload(imageSrc: any) {
    const canvas: any = canvasRef.current;
    const link = document.createElement("a");
    link.download = "QRcode.png";
    link.href = imageSrc ;
    link.click();
  }

  useEffect(() => {
    if (usersDetails?.qrcode) {
      drawCanvas();
    }
  }, [usersDetails?.qrcode]);

  const drawCanvas = () => {
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = usersDetails?.qrcode;

    // console.log("crossOrigin", img);
    img.addEventListener("load", (e) => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    });
  };
  const handleCancelClose = (value: any) => {
    setOpenPopup(false); 
  };
  const handleDownloadBAnner = () => {
 
    if (containerRef.current) {
      html2canvas(containerRef.current).then((canvas: { toDataURL: (arg0: string) => string; }) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'combined-image.png';
        link.click();
      });
    }
  };
 
  return (
    <div className="contentBox">
      <PageTitle name={"MY PROFILE"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <div className="row d-flex justify-content-between bgwhite mt-3">
          <div className="col-md-4 p-1">
            <div className="border border-2 rounded p-3 h-100">
              <div className="col-sm-12 text-center  mt-3">
                <div className="d-flex justify-content-center ">
                  <img
                    className="img-fluid w-50"
                    src={usersDetails?.qrcode}
                    alt="qrcode"
                  />
                </div>
                <div className=" d-flex justify-content-center mt-3">
                  <p className=" fw-bold mb-1 text-uppercase">{userName}</p>
                </div>
                <div className=" d-flex justify-content-center ">
                  <p className="mb-1 ">
                    Download QR Code{" "}
                    <span className="cursor">
                      <DownloadIcon
                        onClick={() => handleDownload(usersDetails?.qrcode)}
                      />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 p-1">
            <div className="border border-2 rounded p-3 row d-flex justify-content-between h-100">
              <div className="col-sm-6 ps-0  ">
                <label className="mb-2 d-block">First Name</label>
                <div className="d-flex">
                  <input
                    type="text"
                    placeholder="Enter First Name "
                    className="form-control "
                    value={usersDetails?.name}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 ps-0  ">
                <label className="mb-2 d-block">Mobile Number</label>
                <div className="d-flex ">
                  <input
                    type="text"
                    placeholder="Enter Mobile Number"
                    className=" form-control "
                    value={usersDetails?.mobile}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-sm-6 ps-0   position-relative">
                <label className="mb-2 d-block">Email Address</label>
                <div className="d-flex">
                  <input
                    type="email"
                    placeholder="Enter Mail ID"
                    className="form-control "
                    value={usersDetails?.email}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 ps-0 ">
                <label className="mb-2 d-block">User Name</label>
                <div className="d-flex ">
                  <input
                    type="text"
                    placeholder="Enter user name"
                    className=" form-control "
                    value={usersDetails?.username}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 ps-0 mt-2 ">
                <label className="mb-2 d-block">Date Of Joining</label>
                <DatePicker
                  value={usersDetails?.doj}
                  setInputText={(value: string) => setDateOfJoining(value)}
                  disabled={true}
                />
              </div>
              <div className="col-sm-6 ps-0 mt-2 ">
              <button className="btn btn-primary rounded fw-bold"  onClick={() => setOpenPopup(true)} >
                   Create Your Banner
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <canvas ref={canvasRef} id="canvas" style={{display:"none"}}></canvas>
        </div>

        {/* <div
        ref={containerRef}
        style={{
          position: 'relative',
          width: '500px', // Adjust size as needed
          height: '500px', // Adjust size as needed
        }}
      >
        <img
          src={usersDetails?.bannerimage} // Background image
          alt="Background"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
        <img
          src={usersDetails?.qrcode} // Overlay image
          alt="Overlay"
          style={{
            position: 'absolute',
            top: '100px', // Adjust position as needed
            left: '100px', // Adjust position as needed
            width: '200px', // Adjust size as needed
            height: '200px', // Adjust size as needed
          }}
        />
      </div> */}
      {/* <div className="col-sm-6 ps-0 mt-2 ">
              <button className="btn btn-primary rounded fw-bold"  onClick={handleDownloadBAnner} >
                  Banner download
                  </button>
              </div> */}
      
      </div>
       <CreateBannerPopup
         open={openPopup}
         handleClose={handleCancelClose}
            data={usersDetails?.bannerimage}
        //  getList={getList}
        />  
    </div>
  );
}
