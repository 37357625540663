import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import {
  getTableDataSlots,
  today,
  usePagination,
} from "../../Utils/appConstants";
import Pagination from "@mui/material/Pagination";
import SubmitButton from "../../Components/searchButton";
import {
  getAffilateMarketingLead,
  getCountryList,
  getLandingLeads,
  getLandingPageSales,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import ExportButton from "../../Components/exportButton";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import LeadDemoAlignPopup from "../../Components/leadDemoAlignPopup";
import moment from "moment";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import AffilateLeadDeatilsPopup from "../../Components/affilateLeadDeatilsPopup";
export default function AffiliateLeads() {
  const dispatch: Function = useDispatch();
  const tableRef = useRef(null);
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);
  const [startDate, setStartDate] = useState<any>(
    moment(lastWeek).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(today);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [leads, setLeads] = useState([]);
  const [country, setCountry] = useState<any>({
    _id: 226,
    phonecode: 1,
    name: "UNITED STATES",
  });
  const [countryList, setCountryList] = useState([]);
  const [openCancelPopup, setOpenCancelPopup] = useState(false); 
  const [selectedData, setSelectedData] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<any>(3);

  const { userName, userId } = useSelector(
    (state: { Auth: AuthReducerModal }) => ({
      userName: state.Auth.userName,
      userId: state.Auth.userId,
    })
  );
console.log("country",country)
  useEffect(() => {
    getDemoReq();
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.msg) as any);
        }
      )
    );
  }, []);

  const getDemoReq = () => {
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 6,
      countryId: country?._id,
      refId:userId
    };
    dispatch(
      getAffilateMarketingLead(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        (error: any) => { }
      )
    );
  };
 
 
  const handleNavigate = (value: any) => {
    console.log("handleNavigate",value) 
    setSelectedData(value)
    setOpenCancelPopup(true);
  };
  const handleCancelClose = (value: any) => {
    setOpenCancelPopup(false); 
  };
  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };

  const onChangeCountryName = (e: any, index: any, data: any) => {
    setLeads([]);
    setSelectedIndex(index);
    setCountry(data);
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 6,
      countryId: data?._id,
      refId:userId
    };
    dispatch(
      getAffilateMarketingLead(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        (error: any) => { }
      )
    );
  };

  const onPageChange = (page: number, rowsPerPage: number) => {
    let postdata: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 6,
      countryId: country?._id,
      refId:userId,
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    };

    dispatch(
      getAffilateMarketingLead(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        () => { }
      )
    );
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }

    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const renderListMode = () => {
    return (
      <>
        <div className="">
          <div className="d-flex justify-content-between mt-4">
            <div className="justify-content-start mt-4 ms-3">
              {countryList?.map((item: any, index: any) => {
                return (
                  <button
                    className={`btn btn-sm me-4 ${selectedIndex === index + 1
                        ? "btn-success"
                        : "btn-secondary"
                      }`}
                    onClick={(e) => onChangeCountryName(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                );
              })}
            </div>
            <div className="d-flex justify-content-end mt-4">
              {leads?.length !== 0 && leads?.length !== 0 ? (
                <div className=" d-flex justify-content-end ms-2">
                  <DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                  >
                    <ExportButton name={"Export Details"} />
                  </DownloadTableExcel>
                </div>
              ) : (
                ""
              )}
              {leads?.length !== 0 ? (
                <div className="d-flex paginationdiv justify-content-end align-items-center ms-2  ">
                  <Pagination
                    count={totalPages}
                    size="large"
                    page={currentPage}
                    variant="outlined"
                    shape="rounded"
                    color={"primary"}
                    onChange={handleChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={leads ? leads : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}
        <div>
          <PaginationTable
            tableData={leads ? leads : []}
            pagename={"AffilateLandingPageleads"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "STUDENT NAME",
              "STUDENT MOBILE NUMBER",
              "STUDENT EMAIL",
              "CREATED DATE",
              "DEMO STATUS",
               "ACTION",
            ]}
            // edit={true} 
            handleNavigate={(value: any) => handleNavigate(value)}

          />
        </div>
        <div ref={tableRef} className="d-none">
          <PaginationTable
            tableData={leads ? leads : []}
            pagename={"AffilateLandingPageleads"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "STUDENT NAME",
              "STUDENT MOBILE NUMBER",
              "STUDENT EMAIL",
              "CREATED DATE",
              "DEMO STATUS",
            ]}
            edit={true} 
            handleNavigate={(value: any) => handleNavigate(value)}

          />
        </div>
      </>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getDemoReq();
  };
console.log("selectedData",selectedData)
  return (
    <div className="contentBox">
      <PageTitle name={"AFFILATED LEADS"} />
      <div className="formDiv">
        <form className="text-center " onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex flex-wrap Justify-content-between ">
            <div className="col-md-2 d-flex mb-2 align-items-center ms-5">
              <LabelField lableName={"Start Date:"} />
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={today}
              />
            </div>
            <div className=" col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"End Date:"} />
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={today}
              />
            </div>

            <div className="d-flex col-md-2 ms-3  ">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
        {renderListMode()}
      </div>
      < AffilateLeadDeatilsPopup 
              open={openCancelPopup}
              handleClose={handleCancelClose}
              data={selectedData}
              // getList={getList}
            />
    </div>
  );
}
