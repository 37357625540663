import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  validateIsNumberOnly,
  length_TwoHundredFifty,
} from "../../Utils/validators";
import "../../App.css";
import CustomDropdown from "../../Components/customDropdown";
import {
  addlectureCharges,
  getCountryList,
  getLectureCharges,
} from "../../Redux/Actions/AdminAction";

export default function LectureCharges() {
  const dispatch: Function = useDispatch();
  const [classCharges, setClassCharges] = useState<any>("");
  const [classDiscount, setClassDiscount] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    setClassCharges("");
    setClassDiscount("");
    const Postdata = {
      countryId: value?._id,
    };

    dispatch(
      getLectureCharges(
        Postdata,
        (res: any) => {
          setClassCharges(res[0]?.charges);
          setClassDiscount(res[0]?.extraLectureDiscountPercentage);
        },
        () => { }
      )
    );
  };

  const onChangeClassDiscount = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setClassDiscount(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeClassCharges = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setClassCharges(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const Postdata = {
      countryId: country?._id,
      charges: classCharges,
      extraLectureDiscountPercentage: classDiscount,
    };

    dispatch(
      addlectureCharges(
        Postdata,
        (res: any) => {
          setResetCountryList(true);
          setClassCharges("");
          setClassDiscount("");
        },
        () => { }
      )
    );
  };

  function validation() {
    return country === "" || classCharges === "" || classDiscount === "";
  }
  return (
    <div className="contentBox">
      <PageTitle name={"LECTURE CHARGES"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className=" row col-7 mt-2">
            <div className="d-flex Justify-content-between p-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-3 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>

            <div className="d-flex Justify-content-between  p-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Per Class Charges"} />
              </div>
              <div className="col-6 ps-2">
                <InputField
                  placeholder="Enter Per Class Charges"
                  value={classCharges}
                  isRequired={true}
                  onChangeInput={(value: string) => onChangeClassCharges(value)}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between p-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Discount Per Class"} />
              </div>
              <div className="col-6 ps-2">
                <InputField
                  placeholder="Enter Extra Class Discount"
                  value={classDiscount}
                  onChangeInput={(value: string) =>
                    onChangeClassDiscount(value)
                  }
                  isRequired={true}
                  maxlength={length_TwoHundredFifty - 1}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <Submitbtn name={"Update"} validation={validation()} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
