import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import teachersSidebarData from "./teachersSidebarData";
import Dashboard from "./dashboard";
import UpcomingSchedule from "./upcomingSchedule";
import Feedback from "./feedback";
import SubjectFeedback from "./subjectFeedback";
import WIP from "../wip";
import MyClass from "./myClass";
import AnswerReq from "./answerReq";
import MyDemo from "./myDemo";
import TimeAvailability from "./timeAvailability";
import DemoConducted from "./demoConducted";
import DemoSchedule from "./demoSchedule";
import ClassSchedule from "./classSchedule";
import ClassConducted from "./classConducted";
import PaymantsAndCoupons from "./paymantsAndCoupons";
import TeacherFeedback from "./teacherFeedback";
import Notification from "./notification";
import DemoPending from "./demoPending";
import ClassPending from "./classPending";
import TeacherLiveFeedback from "./teacherLiveFeedback";
import StudentLiveFeedback from "./studentLiveFeedback";
import UpgradeStudentLevel from "./upgradeStudentLevel";
export default function TeachersPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={teachersSidebarData?.TeachersSidebarData} />
      <div className="dashboard">
        <Routes>
          <Route path="/" element={<MyClass />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="upcomingSchedule" element={<UpcomingSchedule />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="subjectFeedback" element={<SubjectFeedback />} />
          <Route path="myClass" element={<MyClass />} />
          <Route path="answerReq" element={<AnswerReq />} />
          <Route path="demoSchedule" element={<DemoSchedule />} />
          <Route path="myDemo" element={<MyDemo />} />
          <Route path="timeAvailability" element={<TimeAvailability />} />
          <Route path="demoConducted" element={<DemoConducted />} />
          <Route path="classSchedule" element={<ClassSchedule />} />
          <Route path="classConducted" element={<ClassConducted />} />
          <Route path="paymantsAndCoupons" element={<PaymantsAndCoupons />} />
          <Route path="teacherFeedback" element={<TeacherFeedback />} />
          <Route path="notification" element={<Notification />} />
          <Route path="demoPending" element={<DemoPending />} />
          <Route path="classPending" element={<ClassPending />} />
          <Route path="teacherLiveFeedback" element={<TeacherLiveFeedback />} />
          <Route path="studentLiveFeedback" element={<StudentLiveFeedback />} />
          <Route path="upgradeStudentLevel" element={<UpgradeStudentLevel />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
