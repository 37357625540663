import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import CustomDropdown from "../../Components/customDropdown";
import { useDispatch } from "react-redux";
import { TypeList } from "../../Utils/dataConstant";
import InputField from "../../Atoms/InputField";
import {
  isValidDiscount,
  length_Two,
  length_TwoHundredFifty,
  validateDecimal,
  validateIsNumberOnly,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import {
  createCoupon,
  getCounsellorList,
  getCountryList,
  getCourseTypelist,
} from "../../Redux/Actions/AdminAction";
import { discountValue, isValidDiscountErrMsg } from "../../Utils/errorMsg";

export default function CreateCouponCode() {
  const dispatch: Function = useDispatch();
  const [type, setType] = useState<any>("");
  const [resetTypeList, setResetTypeList] = useState(false);
  const [couponName, setCouponName] = useState<any>("");
  const [discPercent, setDiscPercent] = useState<any>("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [mindate, setmindate] = useState("");
  const [toggle, setToggle] = useState(false);
  const [counsellorName, setCounsellorName] = useState<any>("");
  const [counsellorNameList, setCounsellorNameList] = useState<any>([]);
  const [resetcounsellorName, setResetcounsellorName] = useState<any>([]);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [forAllToggle, setForAllToggle] = useState(false);
  const [discPercentGlobal, setDiscPercentGlobal] = useState<any>("");

  useEffect(() => {
    setmindate(moment(new Date()).format("YYYY-MM-DD"));
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    const postData = {};
    dispatch(
      getCounsellorList(
        postData,
        (data: any) => {
          setCounsellorNameList(data);
        },
        () => { }
      )
    );
  }, []);

  const onChangeType = (value: any) => {
    setType(value);
    setResetTypeList(false);
    if (value?._id === 1) {
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el: any) => (el.checked = false));
    }
  };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
  };
  const onChangeCounsellor = (value: any) => {
    setCounsellorName(value);
    setResetcounsellorName(false);
  };
  const onchangeCouponName = (value: any) => {
    setCouponName(value);
  };
  const onchangeDiscPercentGlobal = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setDiscPercentGlobal(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onchangeDiscPercent = (value: any) => {
    if (!isValidDiscount(value)) {
      dispatch(showErrorSnackbar(isValidDiscountErrMsg));
      return;
    }
    if (!validateDecimal(value)) {
      dispatch(showErrorSnackbar("Please enter between 1 and 100 only"));
      return;
    } else if (value > 100) {
      dispatch(showErrorSnackbar(discountValue));
      return;
    } else {
      let CouponName = "";
      if (counsellorName) {
        let checkedNew = value.split(".").join("");
        CouponName = counsellorName?.uniqueId + checkedNew;
      }
      setCouponName(CouponName);
      setDiscPercent(value);
    }
  };
  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  const changeForAllToggle = (event: any) => {
    if (event.target.checked) {
      setForAllToggle(true);
    } else {
      setForAllToggle(false);
    }
  };
  function validation() {
    return type === "" || startDate === "" || endDate === "";
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (type?._id === 1) {
      const postdata = {
        couponType: type?._id,
        countryId: country?._id,
        coursetypeId: course?._id,
        couponName: couponName,
        discPercentage: discPercentGlobal,
        startDate: startDate,
        endDate: endDate,
        isActive: toggle,
      };
      dispatch(
        createCoupon(
          postdata,
          (res: any) => {
            setResetTypeList(true);
            setResetCountryList(true);
            setResetParentCourse(true);
            setDiscPercentGlobal("");
            setCouponName("");
            setDiscPercent("");
            setStartDate("");
            setEndDate("");
            setToggle(false);
            document
              .querySelectorAll("input[type=checkbox]")
              .forEach((el: any) => (el.checked = false));
          },
          () => { }
        )
      );
    }

    if (type?._id == 2 && forAllToggle === true) {
      const postdata = {
        couponType: type?._id,
        forAll: forAllToggle,
        countryId: country?._id,
        coursetypeId: course?._id,
        discPercentage: discPercent,
        startDate: startDate,
        endDate: endDate,
        isActive: toggle,
      };
      dispatch(
        createCoupon(
          postdata,
          (res: any) => {
            setResetTypeList(true);
            setCouponName("");
            setDiscPercent("");
            setStartDate("");
            setEndDate("");
            setToggle(false);
            document
              .querySelectorAll("input[type=checkbox]")
              .forEach((el: any) => (el.checked = false));
          },
          () => { }
        )
      );
    }

    if (type?._id == 2 && forAllToggle === false) {
      const postdata = {
        couponType: type?._id,
        countryId: country?._id,
        coursetypeId: course?._id,
        counsellorName: counsellorName?.name,
        counsellorId: counsellorName?._id,
        discPercentage: discPercent,
        couponName: couponName,
        startDate: startDate,
        endDate: endDate,
        isActive: toggle,
        forAll: forAllToggle,
      };
      dispatch(
        createCoupon(
          postdata,
          (res: any) => {
            setResetTypeList(true);
            setResetCountryList(true);
            setResetParentCourse(true);
            setResetcounsellorName(true);
            setCounsellorName("");
            setCouponName("");
            setDiscPercent("");
            setStartDate("");
            setEndDate("");
            setToggle(false);
            document
              .querySelectorAll("input[type=checkbox]")
              .forEach((el: any) => (el.checked = false));
          },
          () => { }
        )
      );
    }
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE COUPON CODE"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7 pt-2">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Type"} />
              </div>
              <div className="col-7 ps-2 ">
                <CustomDropdown
                  lableName="Select Type"
                  setInputText={(value: any) => onChangeType(value)}
                  value={type}
                  options={TypeList}
                  Isrequired={true}
                  reset={resetTypeList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"For All"} />
              </div>
              <div className="col-2 ps-1 ">
                <ToggleSwitchbtn
                  disabled={type?._id === 1 ? true : false}
                  getToggleval={(value: any) => {
                    changeForAllToggle(value);
                  }}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-7 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-7 ps-2 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                  reset={resetParentCourse}
                />
              </div>
            </div>

            {type?._id === 2 && forAllToggle === false ? (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Counsellor Name"} />
                </div>
                <div className="col-7 ps-2 ">
                  <CustomDropdown
                    lableName="Select Counsellor"
                    setInputText={(value: any) => onChangeCounsellor(value)}
                    value={counsellorName}
                    options={counsellorNameList}
                    Isrequired={true}
                    reset={resetcounsellorName}
                  />
                </div>
              </div>
            ) : null}
            {forAllToggle === true || type?._id === 1 ? null : (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Unique ID"} />
                </div>
                <div className="col-7 ps-2 ">
                  <InputField
                    placeholder="Counsellor Unique ID"
                    value={counsellorName?.uniqueId}
                    isRequired={true}
                    onChangeInput={(value: any) => onChangeCounsellor(value)}
                    disabled={true}
                  />
                </div>
              </div>
            )}
            {type?._id === 1 ? (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Coupon Name"} />
                </div>
                <div className="col-7 ps-1 ">
                  <InputField
                    placeholder="Enter Coupon Name"
                    value={couponName}
                    isRequired={true}
                    onChangeInput={(value: any) => onchangeCouponName(value)}
                    maxlength={length_TwoHundredFifty - 1}
                    minlength={length_Two + 1}
                  />
                </div>
              </div>
            ) : null}

            {type?._id === 1 ? (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Discount Percent"} />
                </div>
                <div className="col-7 ps-1 ">
                  <InputField
                    placeholder="Enter Discount %"
                    value={discPercentGlobal}
                    isRequired={true}
                    onChangeInput={(value: any) =>
                      onchangeDiscPercentGlobal(value)
                    }
                    maxlength={length_TwoHundredFifty - 1}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Discount Percent"} />
                </div>
                <div className="col-7 ps-1 ">
                  <InputField
                    placeholder="Enter Discount %"
                    value={discPercent}
                    isRequired={true}
                    onChangeInput={(value: any) => onchangeDiscPercent(value)}
                    maxlength={length_TwoHundredFifty - 1}
                  />
                </div>
              </div>
            )}

            {forAllToggle === true || type?._id === 1 ? null : (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Coupon Name"} />
                </div>
                <div className="col-7 ps-1 ">
                  <InputField
                    placeholder="Enter Coupon Name"
                    value={couponName}
                    isRequired={true}
                    onChangeInput={(value: any) => onchangeCouponName(value)}
                    maxlength={length_TwoHundredFifty - 1}
                    minlength={length_Two + 1}
                    disabled={true}
                  />
                </div>
              </div>
            )}

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Validity"} />
              </div>
              <div className="d-flex col-10">
                <div className="d-flex col-5">
                  <LabelField lableName={"Start Date"} />
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => setStartDate(value)}
                    mindate={mindate}
                  />
                </div>
                <div className="d-flex col-5">
                  <LabelField lableName={"End Date"} />
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => setEndDate(value)}
                    mindate={mindate}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Status"} />
              </div>
              <div className="col-2 ps-1 ">
                <ToggleSwitchbtn
                  getToggleval={(value: any) => {
                    changeToggle(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
