import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import academicsSidebarData from "./academicsSidebarData";
import IndexAcademics from "./indexAcademics";
import AddPageAcademics from "./addPageAcademics";
import AcademicsAddData from "./academicsAddData";


export default function AcademicsPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={academicsSidebarData?.AcademicsSidebarData} />

      <div className="dashboard">
        <Routes>
          <Route path="/" element={<AcademicsAddData />} />
          <Route path="academicsAddData" element={<AcademicsAddData />} />
          <Route path="indexAcademics" element={<IndexAcademics />} />
          <Route path="addPageAcademics" element={<AddPageAcademics />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
